<template>
  <div class="subscriptions">
    <div v-if="homePage" class="pricing-choice">
      <div class="pricing-switch">
        <p :class="{ activeSubscription: !subscriptions.monthly }">
          {{ $t('subscriptions.price_m') }}
        </p>

        <el-switch
          class="switch"
          v-model="subscriptions.monthly"
          style="--el-switch-on-color: #2667ff; --el-switch-off-color: #555555"
        />
        <p :class="{ activeSubscription: subscriptions.monthly }">
          {{ $t('subscriptions.price_a') }}
        </p>
      </div>
      <p>{{ $t('subscriptions.free_month') }}</p>
    </div>

    <div v-else class="other-page">
      <div class="other-page-title">
        <h1>{{ $t('settings.subscriptions.title') }}</h1>
        <p class="free-month">{{ $t('subscriptions.free_month') }}</p>
      </div>
      <div class="other-page-switch">
        <p :class="{ activeSubscription: !subscriptions.monthly }">
          {{ $t('subscriptions.price_m') }}
        </p>
        <el-switch
          class="switch"
          v-model="subscriptions.monthly"
          style="--el-switch-on-color: #2667ff; --el-switch-off-color: #555555"
        />
        <p :class="{ activeSubscription: subscriptions.monthly }">
          {{ $t('subscriptions.price_a') }}
        </p>
      </div>
    </div>

    <div class="pricing-user-type" v-if="!getAuthStatus">
      <button
        :class="{ activeType: subscriptions.individual }"
        @click="subscriptions.individual = true"
      >
        {{ $t('subscriptions.individual') }}
      </button>
      <button
        :class="{ activeType: !subscriptions.individual }"
        @click="subscriptions.individual = false"
      >
        {{ $t('subscriptions.pro') }}
      </button>
    </div>

    <!-- <div class="pricing-user-type-connected" v-else-if="displayOneOffer">
      <button
        v-if="type !== true"
        :class="{ activeType: subscriptions.individual }"
        @click="subscriptions.individual = true"
      >
        {{ $t('subscriptions.individual') }}
      </button>
      <button
        v-else
        :class="{ activeType: !subscriptions.individual }"
        @click="subscriptions.individual = false"
      >
        {{ $t('subscriptions.pro') }}
      </button>
    </div> -->

    <div class="subscriptions-body">
      <div class="title-bloc">
        <h3>{{ $t('subscriptions.premium') }}</h3>
        <p>{{ $t('subscriptions.subtitle') }}</p>
        <img
          class="title-bloc-background"
          src="@/assets/svg/v2/home/background.svg"
          alt="Subscriptions background"
        />
      </div>

      <div class="subscriptions-list">
        <div class="subscriptions-list-left">
          <div class="price">
            <h4 v-if="subscriptions.individual && !subscriptions.monthly">
              5.99 EUR
              <p class="tax">{{ $t('subscriptions.all_taxs') }}</p>
            </h4>
            <h4 v-else-if="!subscriptions.individual && !subscriptions.monthly">
              9.99 EUR
              <p class="tax">{{ $t('subscriptions.all_taxs') }}</p>
            </h4>

            <h4 v-if="subscriptions.individual && subscriptions.monthly">
              65.89 EUR
              <p class="tax">{{ $t('subscriptions.all_taxs') }}</p>
            </h4>
            <h4 v-else-if="!subscriptions.individual && subscriptions.monthly">
              109.89 EUR
            </h4>

            <div class="complementary">
              <p v-if="!subscriptions.monthly">
                {{ $t('subscriptions.per_month') }}
                {{ $t('subscriptions.per_type') }}
              </p>
              <p v-if="subscriptions.monthly">
                {{ $t('subscriptions.per_year') }}
                {{ $t('subscriptions.per_type') }}
              </p>
            </div>
          </div>

          <p v-if="subscriptions.monthly" class="free-month">
            {{ $t('subscriptions.free_month') }}
          </p>

          <div class="advantage">
            <!-- <div>
              <img src="@/assets/svg/v2/home/checked.svg" alt="Checked"/>
              <p>{{ $t('subscriptions.references_access') }}</p>
            </div> -->
            <div>
              <img src="@/assets/svg/v2/home/checked.svg" alt="Checked"/>
              <p>{{ $t('subscriptions.price_history') }}</p>
            </div>
            <div>
              <img src="@/assets/svg/v2/home/checked.svg" alt="Checked"/>
              <p>{{ $t('subscriptions.advanced_stats') }}</p>
            </div>

            <button
              @click="redirect('/subscriptions/choice')"
              class="generic-btn"
            >
              <p>{{ $t('subscriptions.subscribe') }}</p>
            </button>
          </div>
        </div>

        <el-divider
          direction="vertical"
          class="subscriptions-divider hide-tel"
        />
        <el-divider class="subscriptions-divider hide-pc" />

        <div class="subscriptions-list-right">
          <p>{{ $t('subscriptions.choice_type') }}</p>

          <div class="spirits-types-list">
            <div class="spirits-types-list-1">
              <div class="rhum-ron-rum">
                <img
                  src="@/assets/svg/v2/categories/rhum_ron_rum.svg"
                  alt="Rhum, Ron & Rum"
                />
                <p>
                  Rhums
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="Rhum, Rum, Ron, Cachaca"
                    placement="top"
                  >
                    <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
                  </el-tooltip>
                </p>
              </div>
              <div class="whiskey-whisky">
                <img
                  src="@/assets/svg/v2/categories/whiskey_whisky.svg"
                  alt="Whiskey & Whisky"
                />
                <p>
                  Whisky
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="Whisky, Whiskey"
                    placement="top"
                  >
                    <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
                  </el-tooltip>
                </p>
              </div>
              <div class="bourbon-rye">
                <img
                  src="@/assets/svg/v2/categories/bourbon_rye.svg"
                  alt="Bourbon & Rye"
                />
                <p>
                  American Whisky
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="Bourbon, Rye, American Whisky"
                    placement="top"
                  >
                    <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
                  </el-tooltip>
                </p>
              </div>
            </div>
            <div class="spirits-types-list-2">
              <div class="tequila">
                <!-- <img src="@/assets/svg/v2/categories/others.svg" alt="others" /> -->
                <img
                  src="@/assets/svg/v2/categories/tequila.svg"
                  alt="Tequila"
                />
                <p>
                  Eau de vie
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="Cognac, Armagnac, Brandy, Grappa, Marc, Kirsh, Schnaps, Eau de vie, Pisco, Calvados, Rakia"
                    placement="top"
                  >
                    <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
                  </el-tooltip>
                </p>
              </div>
              <div class="cognac">
                <!-- <img src="@/assets/svg/v2/categories/others.svg" alt="others" /> -->
                <img src="@/assets/svg/v2/categories/cognac.svg" alt="Cognac" />
                <p>
                  Agave, Distillate & Various
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="Vodka, Gin, Genever, Sake, Shochu, Soju, Baiju, Mezcal, Tequila, Sotol, Bacanora, Various"
                    placement="top"
                  >
                    <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
                  </el-tooltip>
                </p>
              </div>
              <div class="liquors">
                <img
                  src="@/assets/svg/v2/categories/liquors.svg"
                  alt="Liquors"
                />
                <p>
                  Liqueur & Aperitif
                  <el-tooltip
                    class="tooltip"
                    effect="dark"
                    content="Chartreuse, Liqueur, Absinthe, Ouzo, Crème"
                    placement="top"
                  >
                    <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
                  </el-tooltip>
                </p>
              </div>
              <!-- <div class="others">
                <img src="@/assets/svg/v2/categories/others.svg" alt="Others" />
                <p>{{ $t('subscriptions.others') }}</p>
                <img src="@/assets/svg/v2/infos.svg" alt="Informations" class="themed-img"/>
              </div> -->
            </div>
          </div>

          <button
            @click="redirect('/subscriptions/choice')"
            class="generic-btn"
          >
            <p>{{ $t('subscriptions.subscribe') }}</p>
          </button>
        </div>
      </div>
    </div>

    <div
      class="subscriptions-portfolio"
      :class="{ 'year-choice': subscriptions.monthly }"
    >
      <div class="description">
        <div class="title">
          <h3>Portfolio</h3>
          <p>{{ $t('subscriptions.complementary_module') }}</p>
        </div>
        <p v-if="subscriptions.individual" class="portfolio-subtitle">
          {{ $t('subscriptions.portfolio_subtitle') }}
        </p>
        <p v-else class="portfolio-subtitle">
          {{ $t('subscriptions.portfolio_subtitle_pro') }}
        </p>
        <div class="portfolio-advantage">
          <div>
            <img src="@/assets/svg/v2/home/checked.svg" alt="Checked"/>
            <p>{{ $t('subscriptions.advanced_gestion') }}</p>
          </div>
          <div>
            <img src="@/assets/svg/v2/home/checked.svg" alt="Checked"/>
            <p>{{ $t('subscriptions.advanced_stats') }}</p>
          </div>
        </div>
        <p v-if="subscriptions.monthly" class="free-month">
          {{ $t('subscriptions.free_month') }}
        </p>
      </div>
      <div class="price-bloc">
        <div class="price">
          <h4 v-if="subscriptions.individual && !subscriptions.monthly">
            11.99 EUR
          </h4>
          <h4 v-else-if="!subscriptions.individual && !subscriptions.monthly">
            19.99 EUR
          </h4>

          <h4 v-if="subscriptions.individual && subscriptions.monthly">
            131.89 EUR
          </h4>
          <h4 v-else-if="!subscriptions.individual && subscriptions.monthly">
            219.89 EUR
          </h4>

          <p>{{ $t('subscriptions.all_taxs') }}</p>
          <p v-if="!subscriptions.monthly" class="per-month">
            {{ $t('subscriptions.per_month') }}
          </p>
          <p v-else-if="subscriptions.monthly" class="per-month">
            {{ $t('subscriptions.per_year') }}
          </p>
        </div>

        <p class="portfolio-soon">{{ $t('subscriptions.soon') }}</p>

        <!-- <div @click="redirect('/subscriptions/choice')" class="choice-this-offer">
            <p>{{ $t('subscriptions.choice_this_offer') }}</p>
            <img src="@/assets/svg/v2/arrowToRight.svg" alt="Choice this offer" class="themed-img"/>
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    pro: {
      type: Boolean,
      required: false,
      default: false,
    },
    homePage: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      displayOneOffer: false,
      type: null,

      subscriptions: {
        monthly: false,
        individual: true,
      },
    }
  },
  async mounted() {
    await this.getAuth().then(async () => {
      await this.getClient().then(async (res) => {
        this.displayOneOffer = true
        if (res.address.account_type === 0) {
          this.type = false
          this.subscriptions.individual = true
        } else {
          this.type = true
          this.subscriptions.individual = false
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      getAuthStatus: 'getIsAuth',
    }),
  },
  methods: {
    ...mapActions({
      getClient: 'getAccountDetails',
      getAuth: 'authverification',
    }),
    redirect(path) {
      const type = this.subscriptions.individual === true ? 'individual' : 'pro'
      const duration = this.subscriptions.monthly === false ? 'm' : 'a'

      this.$router.push(`${path}/${type}/${duration}`)
      window.scrollTo(0, 0)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';
.pricing-user-type,
.pricing-choice,
.pricing-choice div,
.spirits-types-list,
.spirits-types-list div,
.spirits-types-list-1,
.spirits-types-list-2,
.subscriptions-list,
.price,
.advantage div,
.portfolio-advantage,
.subscriptions-portfolio,
.price-bloc,
.other-page,
.other-page-switch {
  display: flex;
}

.subscriptions {
  max-width: 869px;
  margin: auto;
  padding-top: 15px;
  margin-bottom: 20px;

  .other-page {
    justify-content: space-between;

    .other-page-title {
      h1 {
        margin-bottom: 1px;
      }
    }

    .other-page-switch {
      align-items: center;
      color: var(--text-color);

      .switch {
        margin-bottom: 0;
        margin: 0 5px;
      }
    }
  }

  .pricing-choice {
    flex-direction: column;
    align-items: center;

    div {
      align-items: center;
      margin-bottom: 12px;

      .switch {
        margin-bottom: 0;
        margin: 0 10px;
      }

      p {
        color: var(--text-color);
      }
    }

    p {
      color: var(--buysuccess-color);
    }
  }

  .pricing-user-type,
  .pricing-user-type-connected {
    border-radius: 12px;
    border: 1px solid var(--border);
    background: var(--background-color-2);
    height: 48px;
    margin-top: 10px;

    button {
      background: transparent;
      border: 0px;
      border-radius: 8px;
      color: var(--text-color);
      margin: 6px;
      width: 450px;
    }
  }

  .pricing-user-type-connected {
    button {
      width: 100%;
    }
  }

  .subscriptions-body {
    margin: auto;
    margin-top: 10px;
    border-radius: 20px;
    border: 1px solid var(--border-1);
    background: var(--background-color-2);
    max-width: 807px;
    padding: 24px 30px;
    overflow: hidden;

    .title-bloc {
      position: relative;

      h3 {
        color: var(--text-color);
        font-family: Hyperwave;
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 12px;
      }

      p {
        color: var(--text-color);
        // width: 412px;
      }

      .title-bloc-background {
        position: absolute;
        width: 700px;
        left: 400px;
        top: -200px;
      }
    }
  }
}

.subscriptions-list {
  justify-content: space-between;
  margin-top: 30px;

  .subscriptions-divider {
    height: 239px;
    border-color: var(--border);
    margin: 10px;
  }
}

.subscriptions-list-left {
  .price {
    flex-direction: column;
    h4 {
      display: flex;
      color: var(--text-color);
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .tax {
      color: var(--text-color);
      margin-left: 8px;
      font-size: 20px;
    }

    .complementary {
      color: var(--text-color);

      p {
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  .advantage {
    margin-top: 42px;

    div {
      margin-top: 10px;

      img {
        margin-right: 8px;
      }

      p {
        color: var(--text-color);
        font-size: 15px;
        font-weight: 500;
      }
    }

    .generic-btn {
      margin-top: 31px;
      width: 232px;
    }
  }
}

.subscriptions-list-right {
  .spirits-types-list {
    margin-top: 14px;

    .spirits-types-list-1,
    .spirits-types-list-2 {
      z-index: 5;
      flex-direction: column;

      div {
        display: flex;
        align-items: center;
        margin-top: 12px;

        p {
          margin-left: 8px;
          gap: 5px;
          color: var(--text-color);
          font-size: 15px;
          font-weight: 500;
          display: flex;
          align-items: center;
        }
      }
    }

    .spirits-types-list-1 {
      margin-right: 20px;
    }
  }

  button {
    display: none;
  }
}

.subscriptions-portfolio {
  border-radius: 20px;
  border: 1px solid var(--border-1);
  background: var(--background-color-2);
  padding: 24px 30px;
  margin-top: 15px;
  justify-content: space-between;

  .portfolio-soon {
    margin-top: 20px;
    color: var(--delay-color);
    font-size: 15px;
    font-weight: 500;
  }

  .description {
    .title {
      display: flex;
      margin-bottom: 7px;

      h3 {
        color: var(--text-color);
        font-size: 26px;
        font-weight: 700;
        font-family: 'hyperwave';
      }

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background: var(--background-color-7);
        width: 174px;
        height: 25px;
        margin-left: 18px;
        font-size: 14px;
      }
    }

    .portfolio-subtitle {
      max-width: 397px;
      color: var(--text-color);
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 18px;
      font-size: 16px;
    }

    .portfolio-advantage {
      flex-direction: column;
      div {
        display: flex;
        margin-right: 14px;
        margin-top: 10px;

        img {
          margin-right: 8px;
        }

        p {
          color: var(--text-color);
          font-size: 15px;
          font-weight: 500s;
        }
      }
    }
  }

  .price-bloc {
    flex-direction: column;
    justify-content: space-between;

    .price {
      h4 {
        color: var(--text-color);
        font-size: 22px;
        font-weight: 600;
        margin-right: 4px;
      }

      p {
        color: var(--text-color);
        font-size: 12px;
        font-weight: 700;
      }

      .per-month {
        font-size: 15px;
        font-weight: 600;
        margin-left: 5px;
        margin-top: 5px;
      }
    }

    .choice-this-offer {
      display: flex;
      cursor: pointer;

      p {
        color: var(--light-blue);
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
}

.free-month {
  color: var(--buysuccess-color);
}

.activeSubscription {
  color: $primary-color !important;
}

.year-choice {
  padding: 15px 24px;

  .description {
    .free-month {
      margin-top: 5px;
    }
  }
}

.activeType {
  background: var(--background-color-7) !important;
  color: var(--text-color) !important;
  font-weight: 600 !important;
}

.hide-pc {
  display: none;
}

.hide-tel {
  display: block;
}

@media screen and (max-width: 500px) {
  .subscriptions {
    .pricing-user-type {
      width: 98%;
      margin: 0 5px;
      margin-top: 37px;

      button {
        width: 50%;
      }
    }

    .subscriptions-body {
      height: auto;
      padding: 17px;
      margin: 0 5px;
      margin-top: 16px;

      .title-bloc {
        h3 {
          margin-bottom: 12px;
        }
      }

      .subscriptions-list {
        flex-direction: column;

        .hide-pc {
          display: flex !important;
          height: 2px;
        }

        .hide-tel {
          display: none;
        }

        .subscriptions-list-left {
          display: flex;
          flex-direction: column-reverse;
          margin: 0;
          .price {
            margin-bottom: 15px;
            align-items: center;
            h4 {
              font-size: 30px;
            }

            .complementary {
              p {
                margin: 0;
                font-size: 14px;
              }
            }
          }

          .advantage {
            margin: 0 0 15px;
            button {
              display: none;
            }
          }
        }
        .subscriptions-list-right {
          margin-right: 0;

          .spirits-types-list-1,
          .spirits-types-list-2 {
            div {
              p {
                display: inline;
                position: relative;
                .tooltip {
                  position: absolute;
                  right: 5px;
                  top: 10px;
                  margin-left: 4px;
                }
              }
            }
          }

          button {
            width: 314px;
            margin: auto;
            margin-top: 24px;
            display: flex;

            p {
              color: var(--text-color);
            }
          }
        }
      }
    }
  }

  .subscriptions-portfolio {
    flex-direction: column;
    padding: 17px;
    margin: 10px 5px 0px !important;
    .description {
      .title {
        margin-bottom: 12px;
      }
    }
    .price-bloc {
      margin-top: 25px;
      align-items: center;

      .choice-this-offer {
        margin-top: 15px;
      }
    }
  }

  .portfolio-advantage {
    div {
      img {
        width: 20px;
      }
    }
  }

  .other-page {
    padding: 0 10px;
  }

  .pricing-user-type-connected {
    margin: 0 5px;
  }

  .portfolio-subtitle {
    font-size: 16px !important;
  }
}
</style>
