export default {
  "commons": {
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απορρίψτε τον αγώνα"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία λήξης"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγορά και πώληση"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανταλλαγή"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαρτοφυλάκιο"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιοχή"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσφορά"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρωτήστε"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορά σφάλματος"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος λογαριασμού"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παραγγελίες"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχουσες παραγγελίες"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσότητα"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή στην"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κλείσιμο"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναφορά"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμογή"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προέλευση του προϊόντος"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεχίστε να"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποσύνδεση"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία λογαριασμού"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση και διαπραγμάτευση"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδρομές"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η τιμή περιλαμβάνει ΦΠΑ"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νυχτερινή λειτουργία"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθαρή λειτουργία"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορικό συναλλαγών"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαρτοφυλάκιο"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιστοιχίες"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορικό συναλλαγών"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις λογαριασμού"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιημένο"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απενεργοποιημένο"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδοποιήσεις"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή όλων"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν ειδοποιήσεις προς το παρόν"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν μπορείτε να κάνετε δεξί κλικ"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράδοση"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος της συναλλαγής"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακύρωση"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΦΠΑ"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνολο"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάνετε πάρα πολλά αιτήματα"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε φτάσει στο ημερήσιο όριο διαβούλευσης. Ελέγξτε το λογαριασμό σας ώστε να μην έχετε πλέον περιορισμούς."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε την εγγραφή σας:"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα, προσπαθήστε ξανά αργότερα"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε συνδεθείτε ξανά"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε επικυρώστε το email και το τηλέφωνό σας πριν εκτελέσετε αυτή την ενέργεια"])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν είστε εξουσιοδοτημένοι να εκτελέσετε αυτή την ενέργεια"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η υπηρεσία δεν είναι διαθέσιμη, παρακαλούμε επικοινωνήστε με την υπηρεσία εξυπηρέτησης πελατών"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργό"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε ελέγξτε ότι όλα τα απαιτούμενα πεδία έχουν συμπληρωθεί σωστά"])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε μια γλώσσα"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γαλλικό"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελληνικά"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ισπανικά"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιταλικό"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πολωνική"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γερμανικό"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τσεχική"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δανέζικα"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελληνικό"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ουγγρικό"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιαπωνικό"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κορεατικό"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πορτογαλικά"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΗΝΩΜΈΝΟ ΒΑΣΊΛΕΙΟ"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρωσική"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σλοβακική"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κινέζικο"])}
    },
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφέρετε ένα πρόβλημα"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγοραστής"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πωλητής"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός που αποστέλλεται"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημειώστε ότι ο κωδικός ισχύει για 15 λεπτά."])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άρθρα"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαγγελματικό"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ατομικό"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πορτοφόλι"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πορτοφόλι"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όλα"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε ελέγξτε την εγγραφή σας ή επικοινωνήστε με την υπηρεσία εξυπηρέτησης πελατών"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιγραφή"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτυχημένο αντίγραφο"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φίλτρο :"])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδοποιήσεις"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min :"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max :"])},
    "error_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ενέργεια ακυρώθηκε καθώς ο χρόνος αναμονής έχει ξεπεραστεί. Προσπαθήστε ξανά."])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση"])},
    "spirit_type_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος"])},
    "storage_tep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκη TEP"])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρέπει να είστε άνω των 18 ετών για να επισκεφθείτε αυτόν τον ιστότοπο"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε ελέγξτε την ηλικία σας για να εισέλθετε."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είμαι άνω των 18 ετών"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είμαι κάτω των 18 ετών"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με την πρόσβαση σε αυτόν τον ιστότοπο, συμφωνείτε με την"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["όροι και προϋποθέσεις χρήσης"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["και"])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["πολιτική απορρήτου."])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μια επανάσταση από μόνη της"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το σημείο αναφοράς για την αγορά, την πώληση και την αποθήκευση των αλκοολούχων ποτών σας"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είτε είστε λάτρης του ουίσκι, είτε συλλέκτης κονιάκ, είτε επαγγελματίας που αναζητά την τελειότητα, η πλατφόρμα ανταλλαγής είναι για εσάς. Βέλτιστη ασφάλεια, απόλυτη διαφάνεια και χαμηλές χρεώσεις: ανακαλύψτε τη διαπραγμάτευση αλκοολούχων ποτών με απόλυτη ψυχική ηρεμία."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ασφαλείς συναλλαγές"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μειωμένα τέλη 2,5% συμπεριλαμβανομένου ΦΠΑ"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% επαληθευμένοι δωρεάν λογαριασμοί"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είτε είστε ιδιώτης είτε επαγγελματίας, εξερευνήστε το σύνολο των 70 πηγών τιμών που διαθέτουμε σε χιλιάδες αναφορές αλκοολούχων ποτών!"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε τον δωρεάν λογαριασμό σας χωρίς συνδρομή"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγορά και πώληση: οι συναλλαγές σας είναι ασφαλείς και διαφανείς"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η πλατφόρμα ανταλλαγής καθιστά εύκολη τη συνεχή αγορά και πώληση. Αποκτήστε εύκολη πρόσβαση σε όλες τις διαθέσιμες προσφορές χάρη στις τυποποιημένες αναφορές μας, οι οποίες διευκολύνουν τη σύγκριση και εγγυώνται τη διαφάνεια. Βρείτε αυτό που ψάχνετε ή εκφράστε το ενδιαφέρον σας: το TEP σας επιτρέπει να αντιστοιχίσετε τις ανάγκες σας με τις αναζητήσεις των αντισυμβαλλομένων, είτε αγοράζετε είτε πουλάτε. Εξοικονομήστε χρόνο, βελτιστοποιήστε τις συναλλαγές σας και κάντε πάντα συναλλαγές στην καλύτερη τιμή. Με ένα διαισθητικό περιβάλλον εργασίας και ασφαλείς συναλλαγές, το TEP απλοποιεί τις συναλλαγές με οινοπνευματώδη ποτά, προσφέροντας μια γρήγορη, αξιόπιστη και αποτελεσματική εμπειρία τόσο για ερασιτέχνες όσο και για επαγγελματίες."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανταλλαγή μπουκαλιών: ασφαλές και εγγυημένο χωρίς κίνδυνο αντισυμβαλλομένου"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η TEP είναι η μόνη πλατφόρμα που προσφέρει τη δυνατότητα ανταλλαγής φιαλών με απόλυτη ασφάλεια χάρη στις λύσεις μας για εγγυημένα μετρητά ή καταθέσεις σε αποθήκες. Το σύστημα αυτό εγγυάται απόλυτη προστασία: δεν υπάρχει κίνδυνος αντισυμβαλλομένου, επειδή τα χρήματα είναι εξασφαλισμένα με τον πάροχο υπηρεσιών πληρωμών μας μέχρι να επιβεβαιωθούν οι αντίστοιχες παραδόσεις των φιαλών. Αυτή η δυνατότητα φέρνει επανάσταση στην αγορά οινοπνευματωδών ποτών, καθώς παρέχει πρωτοφανή διαφάνεια και αξιοπιστία. Είτε είστε συλλέκτης είτε γνώστης, το Exchange σας επιτρέπει να επεκτείνετε ή να βελτιστοποιήσετε τη συλλογή σας χωρίς να ανησυχείτε για την ασφάλεια των συναλλαγών. Ανταλλάξτε τα οινοπνευματώδη ποτά σας με απόλυτη ψυχική ηρεμία, σε ένα σύγχρονο και ασφαλές περιβάλλον."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade: Ασφαλίστε τις εξωτερικές σας συναλλαγές με ευκολία"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το Block Trade, ένα αποκλειστικό χαρακτηριστικό της TEP, σας επιτρέπει να εξασφαλίσετε τις συναλλαγές σας για αγορά/πώληση με λίγα μόνο κλικ. Χάρη σε αυτή την καινοτομία, δημιουργείται ένας μοναδικός σύνδεσμος για κάθε συναλλαγή, που εγγυάται αξιόπιστη και ασφαλή εκτέλεση. Είτε αγοράζετε είτε πουλάτε, το Block Trade εξαλείφει τους κινδύνους που σχετίζονται με τις απευθείας εξωτερικές ανταλλαγές, παρέχοντας ένα διαφανές και ασφαλές πλαίσιο. Ενσωματώνει επίσης προηγμένα εργαλεία για την επιβεβαίωση των όρων της συναλλαγής, την επαλήθευση των λογαριασμών και τη διασφάλιση της ομαλής εκτέλεσης σε κάθε στάδιο. Ανακαλύψτε έναν νέο τρόπο να εμπορεύεστε τα πνεύματά σας, με την ηρεμία και την αξιοπιστία που προσφέρει η TEP."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση χαρτοφυλακίου: μια επανάσταση για συλλέκτες και επενδυτές"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για τους πιο έμπειρους, η πλατφόρμα ανταλλαγής προσφέρει ένα μοναδικό εργαλείο διαχείρισης χαρτοφυλακίου. Αυτή η λειτουργία σας επιτρέπει να παρακολουθείτε τις θέσεις σας και την αξία τους σε πραγματικό χρόνο χρησιμοποιώντας τις ζωντανές τιμές από την πλατφόρμα, σε συνδυασμό με τα λεπτομερή ιστορικά δεδομένα μας. Τέρμα οι ατελείωτες ερωτήσεις σχετικά με την αξία της συλλογής σας! Με μια άνευ προηγουμένου αντικειμενική και αμερόληπτη άποψη, μπορείτε να παρακολουθείτε την εξέλιξη των περιουσιακών σας στοιχείων και να λαμβάνετε τεκμηριωμένες αποφάσεις. Είτε αξιολογείτε τη συλλογή σας είτε σχεδιάζετε στρατηγικές συναλλαγές, το εργαλείο διαχείρισης χαρτοφυλακίου της TEP μεταμορφώνει την εμπειρία σας παρέχοντας απόλυτη διαφάνεια και βέλτιστο έλεγχο των πνευμάτων σας."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορικές τιμές: ασυναγώνιστη διαφάνεια"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στην TEP, έχουμε συγκεντρώσει το πιο ολοκληρωμένο ιστορικό τιμών όλων των πιθανών οινοπνευματωδών ποτών, με δεδομένα που ξεκινούν από το 2002! Χάρη στη συγκέντρωση 70 διαφορετικών πηγών, που συμπληρώνονται από τις δικές μας συναλλαγές, προσφέρουμε μια διαφανή και αγνωστική άποψη των παρελθουσών τιμών που πραγματοποιήθηκαν. Όλα αυτά τα δεδομένα τυποποιούνται προσεκτικά και διατίθενται σε 18 διαφορετικά νομίσματα, επιτρέποντας στους χρήστες μας να συγκρίνουν και να αναλύουν τις τάσεις της αγοράς σε παγκόσμια κλίμακα. Αυτή η μοναδική λειτουργικότητα αποτελεί απαραίτητο εργαλείο για συλλέκτες, επενδυτές και επαγγελματίες που επιθυμούν να λαμβάνουν τεκμηριωμένες αποφάσεις, είτε πρόκειται για αγορά, είτε για πώληση, είτε απλώς για αξιολόγηση του χαρτοφυλακίου των αλκοολούχων ποτών τους."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγύηση εμπιστοσύνης"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι πληρωμές πραγματοποιούνται μέσω του παρόχου υπηρεσιών πληρωμών Mangopay για να διασφαλιστεί η ομαλή διεξαγωγή των συναλλαγών, δεν υπάρχει κίνδυνος εξαφάνισης των χρημάτων που δεν θα στείλετε ποτέ στον αντισυμβαλλόμενο."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαμηλότερες χρεώσεις στην αγορά"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχουμε τα χαμηλότερα τέλη στην αγορά, 2,5% συμπεριλαμβανομένου του ΦΠΑ για τον αγοραστή και τον πωλητή, χωρίς τέλη καταχώρισης ή αποθεμάτων. Πληρώνετε μόνο αν υπάρξει πραγματική συναλλαγή."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανωνυμία πριν από τη συναλλαγή"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είτε είστε ιδιώτης είτε επαγγελματίας, η πλατφόρμα μας είναι η λύση σε όλα τα σημερινά προβλήματα χάρη στην προ-συναλλακτική ανωνυμία της."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χάρη στον έλεγχο εγγραφής από τον πάροχο υπηρεσιών πληρωμών μας πριν από την επικύρωση του λογαριασμού, κανένας εικονικός λογαριασμός, απατεώνας ή bot δεν μπορεί να εισέλθει στην πλατφόρμα. Η ιχνηλασιμότητα και η ασφάλεια είναι απόλυτες."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μια προσφορά προσαρμοσμένη στις ανάγκες σας"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γίνετε μέλος μιας κοινότητας για να βρείτε, να διαχειριστείτε και να ανταλλάξετε τα οινοπνευματώδη ποτά σας!"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος αλκοολούχων ποτών"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπουκάλι"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρονοδιακόπτης, ονομασία, EAN, λέξεις-κλειδιά..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση εντός"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαθέσιμη διαπραγμάτευση χωρίς συνδρομή!"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είτε είστε ερασιτέχνης, είτε συλλέκτης είτε επαγγελματίας, η Πλατφόρμα Ανταλλαγής είναι φτιαγμένη για εσάς: βέλτιστη ασφάλεια, απόλυτη διαφάνεια και χαμηλό κόστος συναλλαγών, με 70 πηγές ιστορικών τιμών που καλύπτουν χιλιάδες αναφορές φιαλών."])},
    "create_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανακαλύψτε την εμπορία οινοπνευματωδών ποτών με απόλυτη ψυχική ηρεμία."])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed: ο κόμβος ειδήσεων για τα ελεύθερα αλκοολούχα ποτά"])},
    "newsfeed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενημερωθείτε για τα τελευταία νέα του κλάδου, τις νέες κυκλοφορίες, τα σημαντικά γεγονότα και τις τάσεις της αγοράς μέσω του Newsfeed της TEP. Αυτή η λειτουργία σας επιτρέπει να παρακολουθείτε όλα όσα συμβαίνουν στον κόσμο των αλκοολούχων ποτών σε πραγματικό χρόνο, με πληροφορίες από τους πιο σχετικούς και αναγνωρισμένους ιστότοπους του κλάδου. Το Newsfeed σας δίνει μια πλήρη, αγνωστικιστική εικόνα των ειδήσεων, ώστε να μπορείτε να παρακολουθείτε τις εξελίξεις της αγοράς από ένα μέρος."])},
    "blog_and_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog & Review: ο χώρος σας για ανάλυση και ανακάλυψη"])},
    "blog_and_review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξερευνήστε δωρεάν περιεχόμενο γευσιγνωσίας με το Blog & Review του TEP. Με κριτικές από εμπειρογνώμονες και λάτρεις του κλάδου, το Blog & Review σας δίνει πρόσβαση σε μοναδικές προοπτικές και αξιόπιστες συστάσεις. Είτε θέλετε να ανακαλύψετε νέες φιάλες, να κατανοήσετε τις τάσεις της αγοράς ή να διευρύνετε τις γνώσεις σας, αυτή η τροφοδοσία έχει σχεδιαστεί για να εμπλουτίσει την εμπειρία σας στον κόσμο των αλκοολούχων ποτών."])},
    "events_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιηγητής εκδηλώσεων: ο οδηγός σας για τις εκδηλώσεις αλκοολούχων ποτών"])},
    "events_browser_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Με το δωρεάν πρόγραμμα περιήγησης εκδηλώσεων της TEP, δεν θα χάσετε ποτέ άλλη σημαντική συνάντηση στον κόσμο των αλκοολούχων ποτών. Ενημερωθείτε για αποκλειστικές εμπορικές εκθέσεις και εκδηλώσεις όπου κι αν βρίσκεστε. Χάρη σε μια προσεκτικά ενημερωμένη επιλογή, το Events Browser σας φέρνει τις πιο σημαντικές εκδηλώσεις του κλάδου, είτε είστε συλλέκτης, είτε επενδυτής, είτε απλά ένας παθιασμένος λάτρης. Σχεδιάστε τις εξόδους σας και απολαύστε μοναδικές εμπειρίες γύρω από τα αποστάγματα."])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog & κριτική"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκδηλώσεις"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναφορά φίλτρων"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ταξινόμηση με αλφαβητική σειρά"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνίες"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μήνας"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος αλκοολούχων ποτών"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητήστε..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φίλτρο εκδηλώσεων"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία έναρξης"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία λήξης"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["από το"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["στο"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επερχόμενες εκδηλώσεις"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επερχόμενες εκδηλώσεις"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετήσιες συνδρομές"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετήσιες τιμές"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μηνιαίες τιμές"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 μήνας δωρεάν σε ετήσια πληρωμή"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδική προσφορά"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαγγελματική προσφορά"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορικές τιμές premium"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για χρήστες που θέλουν πλήρη ιστορικά δεδομένα χωρίς χρονική υστέρηση."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ανά μήνα"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ανά έτος"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ανά είδος αλκοολούχου ποτού"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσβαση σε όλες τις αναφορές"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορικές τιμές χωρίς χρονική υστέρηση"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγμένα στατιστικά στοιχεία"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγραφείτε σε ένα οινοπνευματώδες ποτό"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλογή αλκοολούχων ποτών :"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κρασιά"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άλλα"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμπληρωματική ενότητα"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για έμπειρους συλλέκτες που θέλουν περισσότερα δεδομένα και ένα πραγματικό εργαλείο διαχείρισης."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γίνετε μέλος μιας κοινότητας ειδικών για να αναλύσετε, να διαχειριστείτε και να εμπορεύεστε τα οινοπνευματώδη ποτά σας!"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγμένη διαχείριση χαρτοφυλακίου"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε αυτή την προσφορά"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη στο καλάθι"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καλάθι"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μηνιαία πληρωμή"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετήσια πληρωμή"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποσύνολο"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επόμενη δειγματοληψία"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνολο"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΦΠΑ (20%)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προχωρήστε στην πληρωμή"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η συνδρομή είναι ήδη στο καλάθι σας"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε ήδη συνδρομητής"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το καλάθι σας είναι άδειο"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιστροφή στις συνδρομές"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στοιχεία επικοινωνίας"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμολόγηση"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέθοδος πληρωμής"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι συναλλαγές στην πλατφόρμα ανταλλαγής πραγματοποιούνται μέσω του παρόχου υπηρεσιών πληρωμών Mangopay."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πιστωτική κάρτα"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός κάρτας"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα στην κάρτα πληρωμής"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία λήξης (MM/YY)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός ασφαλείας"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οριστικοποιήστε την αγορά σας"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύντομα"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνοψη πληρωμών"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προμήθεια αγοράς"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παγκόσμιος ΦΠΑ"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδρομητής"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός επαλήθευσης της κάρτας (στο πίσω μέρος της κάρτας, συνήθως 3 ψηφία"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ακύρωση έχει ληφθεί υπόψη"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ακύρωση απέτυχε, παρακαλούμε επικοινωνήστε με την υπηρεσία εξυπηρέτησης πελατών"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καλώς ήρθατε στην πλατφόρμα ανταλλαγής"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποκτήστε πρόσβαση σε ένα απρόσκοπτο, ασφαλές και τυποποιημένο περιβάλλον συναλλαγών."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση της σύνδεσής μου"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είσοδος"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ξεχάσατε τον κωδικό πρόσβασής σας;"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναφορά κωδικού πρόσβασης"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θα λάβετε ένα μήνυμα ηλεκτρονικού ταχυδρομείου με οδηγίες σχετικά με τον τρόπο επαναφοράς του κωδικού πρόσβασής σας."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ εισάγετε το email σας"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναφορά κωδικού πρόσβασης"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θα λάβετε ένα μήνυμα ηλεκτρονικού ταχυδρομείου με οδηγίες σχετικά με τον τρόπο επαναφοράς του κωδικού πρόσβασής σας."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέος κωδικός πρόσβασης"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση κωδικού πρόσβασης"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός επαλήθευσης"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διπλή αυθεντικοποίηση"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε εισάγετε τον κωδικό που λάβατε μέσω ηλεκτρονικού ταχυδρομείου ή τηλεφώνου"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός πρόσβασής σας πρέπει να περιέχει 7 χαρακτήρες με τουλάχιστον ένα κεφαλαίο γράμμα."])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε συμπληρώστε σωστά όλα τα πεδία"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ εισάγετε έναν έγκυρο κωδικό"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία λογαριασμού"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευγένεια"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ονοματεπώνυμο"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία γέννησης"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εθνικότητα"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιοχή"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διεύθυνση"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός οδού"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ταχυδρομικός κώδικας"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τηλέφωνο"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση κωδικού πρόσβασης"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποδέχομαι την"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["όροι και προϋποθέσεις χρήσης"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["της Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποδέχομαι την"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["όροι και προϋποθέσεις χρήσης"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["και η"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["πολιτική απορρήτου"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Η πλατφόρμα ανταλλαγής."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγραφείτε στο ενημερωτικό μας δελτίο"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισάγετε το email σας"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε τον δωρεάν λογαριασμό μου και συνεχίστε"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολοκληρώστε την εγγραφή μου"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποκτήστε έναν επαληθευμένο λογαριασμό"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επωνυμία της εταιρείας"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πλήρης διεύθυνση"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ταχυδρομικός κώδικας"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαγγελματικό ηλεκτρονικό ταχυδρομείο"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος εταιρείας"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός εταιρείας"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός ΦΠΑ"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εταιρεία μου"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γενικές πληροφορίες"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έλεγχος KYC"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στοιχεία τράπεζας"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στοιχεία τράπεζας"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποκτήστε πρόσβαση σε ένα απρόσκοπτο, ασφαλές και τυποποιημένο περιβάλλον συναλλαγών."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε τη χρήση σας"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο τύπος λογαριασμού δεν μπορεί να αλλάξει μετά την ολοκλήρωση της εγγραφής."])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιδιώτες"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαγγελματικό"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λογαριασμός για προσωπική χρήση"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαληθευμένος επαγγελματικός λογαριασμός"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γενικές πληροφορίες"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε συμπληρώστε όλα τα παρακάτω πεδία"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιδιωτικός λογαριασμός"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εταιρικός λογαριασμός"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαγγελματική κατηγορία"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νομική μορφή"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι δικαιούχοι ιδιοκτήτες που κατέχουν πάνω από 25% ?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλείστε να παράσχετε λεπτομέρειες σχετικά με άλλους πραγματικούς δικαιούχους που κατέχουν ποσοστό άνω του 25%."])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["γεννήθηκε στις"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαμένουν σε"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" και εθνικότητα"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο κωδικός πρόσβασης πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες, συμπεριλαμβανομένων ενός κεφαλαίου γράμματος, ενός μικρού γράμματος, ενός αριθμού και ενός ειδικού χαρακτήρα."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση του ηλεκτρονικού ταχυδρομείου και του αριθμού τηλεφώνου σας"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε εισάγετε τον κωδικό που λάβατε με email"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε εισάγετε τον κωδικό που λάβατε από το τηλέφωνο"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σας έχει αποσταλεί ένα μήνυμα ηλεκτρονικού ταχυδρομείου."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σας έχει αποσταλεί ένα SMS."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν λάβατε τον κωδικό;"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στείλτε στο"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαιωμένο"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εν αναμονή επιβεβαίωσης"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ εισάγετε έναν έγκυρο κωδικό"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συγχαρητήρια, έχετε ήδη δωρεάν πρόσβαση σε πολλές από τις λειτουργίες του ιστότοπου!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θα θέλατε να επαληθεύσετε την ταυτότητά σας τώρα για να απολαύσετε την πλήρη εμπειρία;"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποκτήστε έναν επαληθευμένο λογαριασμό"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποκτήστε έναν δωρεάν επαληθευμένο λογαριασμό"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαλήθευση της ταυτότητάς σας"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε τον τύπο του εγγράφου"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαβατήριο"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δελτίο ταυτότητας"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισάγετε μια φωτογραφία από το διαβατήριό σας"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισάγετε μια φωτογραφία και από τις δύο όψεις της ταυτότητάς σας"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε ένα αρχείο"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σαφές και ευανάγνωστο αρχείο"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέγιστο μέγεθος αρχείου 7 Mb"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μορφή JPG, JPEG ή PNG"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολή για επαλήθευση"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο μέσος χρόνος επαλήθευσης είναι περίπου 24 ώρες."])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιορισμένη λειτουργικότητα"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολοκληρώστε τη διαδικασία εγγραφής και αποκτήστε πρόσβαση σε όλες τις λειτουργίες."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έγγραφα που αποστέλλονται"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επί του παρόντος ελέγχεται"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να αναζητήσετε μπουκάλια και να δείτε ιστορικές τιμές."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν μπορείτε ακόμη να έχετε πρόσβαση στο κομμάτι των συναλλαγών ή να πραγματοποιήσετε συνδρομές."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστέλλεται στο"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόσπασμα Kbis ή ισοδύναμο έγγραφο ηλικίας μικρότερης των τριών μηνών"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντίγραφο του καταστατικού"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαβατήριο ή ταυτότητα, και οι δύο όψεις"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έγγραφο που αποστέλλεται:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έγγραφα που αποστέλλονται:"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refused"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λόγος απόρριψης:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσωπική ταυτότητα"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εταιρική ταυτότητα"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καταστατικό"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δήλωση μετόχων"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαλήθευση διεύθυνσης"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προχωρήστε στην πληρωμή"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη έγκυρος αριθμός κάρτας"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα και επώνυμο στην άκυρη κάρτα"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άκυρη ημερομηνία λήξης"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη έγκυρος κωδικός πιστωτικής κάρτας"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέλη KYC για εταιρείες"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα έγγραφα έχουν αποσταλεί με επιτυχία. Η επικύρωση θα πραγματοποιηθεί εντός 24 ωρών."])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δήλωση των πραγματικών δικαιούχων"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο μέσος χρόνος επαλήθευσης είναι μόλις λίγα λεπτά"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τραβήξτε καθαρές, ευκρινείς φωτογραφίες:"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βεβαιωθείτε ότι η ταυτότητα ή το διαβατήριο είναι πλήρως ορατό, χωρίς σκιές ή αντανακλάσεις, και ότι όλα τα στοιχεία είναι ευανάγνωστα."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή σε συμβατή μορφή:"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρησιμοποιήστε μια μορφή JPEG, JPG ή PNG, με επαρκή ανάλυση ώστε οι πληροφορίες να είναι ευδιάκριτες."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε το πλήρες έγγραφο :"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βεβαιωθείτε ότι όλες οι απαραίτητες σελίδες περιλαμβάνονται ξεχωριστά."])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρέπει να προσθέσετε 2 αποδεικτικά στοιχεία ταυτότητας"])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρέπει να προσθέσετε μια απόδειξη για το διαβατήριο"])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το όνομα του αρχείου σας ή το μέγιστο μέγεθος των 7MB ανά έγγραφο έχει ξεπεραστεί"])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στοιχεία τράπεζας"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισάγετε τα στοιχεία της τράπεζάς σας για να λάβετε τα χρήματα από τις πωλήσεις σας."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα στοιχεία σας θα υπόκεινται σε επικύρωση από το τμήμα εξυπηρέτησης πελατών μας. Θα λάβετε ένα μήνυμα ηλεκτρονικού ταχυδρομείου όταν επικυρωθούν τα τραπεζικά σας στοιχεία."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 0000 0000 000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, αριθμός δρομολόγησης..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιοχή"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε συμπληρώστε"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποβολή"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι συναλλαγές στην πλατφόρμα ανταλλαγής πραγματοποιούνται μέσω του παρόχου υπηρεσιών πληρωμών Mangopay."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα τραπεζικά σας στοιχεία έχουν αποθηκευτεί"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα τραπεζικά σας στοιχεία έχουν ήδη αποσταλεί"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συγχαρητήρια! Ο λογαριασμός σας έχει πλέον επαληθευτεί."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση φιαλών"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσβαση στο χαρτοφυλάκιό μου"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απεριόριστη ενεργή λειτουργία"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε τώρα να έχετε πρόσβαση σε όλο το φάσμα των λειτουργιών."])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμφωνώ να λαμβάνω μηνύματα από την πλατφόρμα ανταλλαγής στον παραπάνω αριθμό τηλεφώνου. Ενδέχεται να ισχύουν χρεώσεις δεδομένων, απαντήστε STOP για να διαγραφείτε."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν αρνηθείτε τα μηνύματα SMS 2FA, δεν θα μπορέσετε να επιβεβαιώσετε τον λογαριασμό σας. Μπορείτε πάντα να διαγραφείτε μετά την επιβεβαίωση του αριθμού τηλεφώνου σας μέσω του συστήματος SMS 2FA."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γενέτειρα"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα γέννησης"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός SIRET"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σαρώστε ή πληκτρολογήστε τον γραμμωτό κώδικα"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσφατες αναζητήσεις"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή όλων"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["προϊόν που εμφανίζεται"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εμφανιζόμενα προϊόντα"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["προϊόν που βρέθηκε με αυτή την αναζήτηση"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["προϊόντα που βρέθηκαν με αυτή την αναζήτηση"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["προϊόν που βρέθηκε"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["προϊόντα που βρέθηκαν"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σας λείπει ένα μπουκάλι;"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φίλτρο κατά"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε όλα"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγοράστε"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πώληση"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συναλλαγές σε μπλοκ"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν μπουκάλια που να ανταποκρίνονται στην αναζήτησή σας."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε το ιστορικό αναζήτησης"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε έναν λογαριασμό για να ξεκλειδώσετε απεριόριστη πρόσβαση"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγραφείτε δωρεάν"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απαιτείται σύνδεση για αυτή τη λειτουργία"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λείπει μπουκάλι"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα της φιάλης"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιγραφή"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εάν είναι απαραίτητο, προσδιορίστε τα στοιχεία της φιάλης που λείπει"])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να περιγράψετε πού οι λόγοι άρνησης"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προαιρετικό"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν αποτελέσματα."])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχουσες παραγγελίες"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σελίδα εμφιαλωτή"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να χρησιμοποιήσετε συνδυασμούς λέξεων-κλειδιών."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η αναζήτηση δεν είναι ευαίσθητη στην πεζότητα."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν χρειάζεται να προσδιορίσετε \"έτη\"."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γράφοντας μια γενική κατηγορία οινοπνευματωδών ποτών (ρούμι, ουίσκι, κονιάκ κ.λπ.) δεν θα έχετε αποτελέσματα."])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε ότι έχετε τουλάχιστον μία λέξη-κλειδί στη γραμμή αναζήτησης."])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Taker"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Giver"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος λογαριασμού"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχουσες παραγγελίες"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατεύθυνση"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία δημιουργίας"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμές"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσότητα"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τροποποίηση εντολής αγοράς"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τροποποίηση εντολής πώλησης"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τροποποίηση συναλλαγών μπλοκ"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τροποποίηση εντολής ανταλλαγής"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση διαγραμμένων εντολών"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θα ήθελα να λάβω ένα"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχω στην κατοχή μου ένα"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["σε αντάλλαγμα για"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τι θα ήθελα να ανταλλάξω"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η παραγγελία τροποποιήθηκε επιτυχώς"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η παραγγελία δημιουργήθηκε επιτυχώς"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνολικό ποσό :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός εντολών :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τίτλος, τιμή..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λόγος άρνησης"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόρριψη"])}
  },
  "product_page": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκε μπουκάλι για αυτή την αναφορά."])},
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη στο χαρτοφυλάκιο"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγορά/Πώληση"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανταλλαγή"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες μπουκαλιών"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνθετη ονομαστική αξία"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τίτλος"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος αλκοολούχων ποτών"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποτοποιία"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφιαλωτής"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ηλικία"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάρκα"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έτος εμφιάλωσης"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιεχόμενα"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός φιάλης"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός παραγόμενων φιαλών"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βάση"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέθοδος παραγωγής"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση του αποστακτηρίου"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ταξινόμηση"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει παραγγελία για αυτό το μπουκάλι"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνθετη ονομαστική αξία"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργό"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανενεργό"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητήστε και προσθέστε ένα μπουκάλι στο διάγραμμα"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, κωδικός ή όνομα προϊόντος"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το μπουκάλι που αναζητάτε δεν έχει βρεθεί"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πωλήσεις"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πηγές"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει διαθέσιμη εικόνα για αυτή την αναφορά"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός βαρελιού"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή ιστορικών τιμών"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ζωντανές εντολές"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός φιαλών"])},
    "orders": {
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία εντολής αγοράς"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία εντολής πώλησης"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία εντολής Swap Taker"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε μια εντολή Swap Giver"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε μια συναλλαγή μπλοκ"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μονάδα βάρους"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέγεθος μονάδας"])},
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγοράζοντας"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πωλήσεις"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία παραγγελίας"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ατομικό"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mix"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προς το παρόν δεν υπάρχουν παραγγελίες."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολή αγοράς"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμή αγοράς"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσότητα"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλογές"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Να προγραμματίσετε μια ημερομηνία και ώρα λήξης;"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία εντολής αγοράς"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολή πώλησης"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσότητα"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βάρος"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μήκος"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πλάτος"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ύψος"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη φωτογραφιών"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλογές"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Να προγραμματίσετε μια ημερομηνία και ώρα λήξης;"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία εντολής πώλησης"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η παραγγελία δημιουργήθηκε επιτυχώς"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το αίτημά σας έχει διεκπεραιωθεί. Θα επικοινωνήσουμε σύντομα μαζί σας"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κανόνες που πρέπει να τηρείτε ως"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποφύγετε την άσκοπη πλημμύρα του βιβλίου εντολών"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιορίστε τις εντολές σας σε πραγματικές συναλλαγές."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποφύγετε τις επανειλημμένες αρνήσεις"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι επανειλημμένες αρνήσεις ενός αγώνα μπορεί να εκληφθούν ως κακόβουλες ή ανεύθυνες. Βεβαιωθείτε ότι συνάπτετε συναλλαγές μόνο εάν σκοπεύετε να τις ολοκληρώσετε."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σεβαστείτε τους χρόνους αποκατάστασης"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η επανειλημμένη αποτυχία έγκαιρης παραλαβής των δεμάτων μπορεί να οδηγήσει σε ταλαιπωρία για τον πωλητή και σε πρόσθετο κόστος."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σεβαστείτε την ακεραιότητα του βιβλίου εντολών"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η χειραγώγηση ή η χειραγώγηση του βιβλίου εντολών, η απενεργοποίηση ενός ενδιαφέροντος μετά την απόρριψη μιας αντιστοίχισης κ.λπ. θεωρούνται παραπλανητικές πρακτικές. Βεβαιωθείτε ότι οι ενέργειές σας είναι διαφανείς."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Να είστε προετοιμασμένοι για το σχετικό κόστος"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδέχεται να ισχύουν τοπικοί εισαγωγικοί και τελωνειακοί φόροι. Βεβαιωθείτε ότι είστε προετοιμασμένοι να τους πληρώσετε κατά τη σύναψη μιας συναλλαγής."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παροχή ακριβών πληροφοριών"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βεβαιωθείτε ότι η περιγραφή της φιάλης είναι ακριβής και αληθινή. Αυτό βοηθάει τους αγοραστές να λάβουν τεκμηριωμένη απόφαση. Παρέχετε μια πλήρη μπροστινή και πίσω φωτογραφία."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σεβαστείτε τις λεπτομέρειες της προσφοράς"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεσμεύεστε να πουλήσετε ένα μπουκάλι, τηρήστε αυτή τη δέσμευση. Κάθε προσφορά που δεν θα τηρηθεί θα έχει συνέπειες για τον πωλητή."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγύηση της ποιότητας της συσκευασίας και του περιεχομένου της"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι επανειλημμένες παραδόσεις κατεστραμμένων δεμάτων (φθορά, θραύση, διαρροή) και λανθασμένου περιεχομένου έχουν αντίκτυπο σε εσάς. Βεβαιωθείτε ότι συσκευάζετε προσεκτικά τις φιάλες σας."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σεβαστείτε τους χρόνους παράδοσης"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η επανειλημμένη μη έγκαιρη αποστολή ενδέχεται να επηρεάσει την πρόσβασή σας στην περιοχή συναλλαγών. Προγραμματίστε αναλόγως για να αποφύγετε τις καθυστερήσεις."])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορά πακέτου:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπορείτε να περιγράψετε λεπτομερώς το πρόβλημά σας εδώ για να διευκολυνθεί η επίλυση του εισιτηρίου"])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση παραλαβής δέματος"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαιώνοντας την παραλαβή του δέματος, επιβεβαιώνετε ότι το προϊόν ανταποκρίνεται στις προσδοκίες σας."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιουσιακά στοιχεία"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναμονή"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ναυτιλία"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στείλτε στο"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε διαμετακόμιση"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παραδίδεται"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δικαστική διαμάχη"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ολοκληρωμένο"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λεπτομέρειες παράδοσης"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός παρακολούθησης"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επί πληρωμή"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακολουθήστε το δέμα μου"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε τις λεπτομέρειες παράδοσης"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατά την παράδοση"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλείστε να αποδεχτείτε ή να απορρίψετε τις προτάσεις σας πριν δημιουργήσετε μια νέα εντολή"])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεσμος Blocktrade"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μοναδικός σύνδεσμος για να μοιραστείτε"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ασφαλής συναλλαγή με τον πάροχο πληρωμών Mangopay"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατεβάστε το δελτίο αποστολής"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στην επαλήθευση"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανώνυμη συναλλαγή (3 EUR με ΦΠΑ)"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρέπει να ανεβάσετε εικόνες της φιάλης"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν μπορείτε να τοποθετήσετε την ίδια εικόνα περισσότερες από μία φορές"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γίνονται δεκτές μόνο εικόνες με μέγεθος μικρότερο από 7 MB και με τις επεκτάσεις .JPG, .JPEG και .png."])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η παραγγελία σας δεν μπορεί να ισχύει για λιγότερο από μία ώρα"])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προς το παρόν δεν υπάρχουν εντολές αγοράς."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προς το παρόν δεν υπάρχουν εντολές πώλησης."])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο αγοραστής δεν θα γνωρίζει την ταυτότητά σας"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχική συλλογή"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το δέμα σας παραλαμβάνεται από το σπίτι σας από την UPS"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία συλλογής"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρώτη ώρα παραλαβής"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία ώρα παραλαβής"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η εντολή δεν ταιριάζει με τις προτιμήσεις της χώρας σας ή του αντισυμβαλλομένου"])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη εικόνων"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ή σύρετε και αφήστε"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".JPG, .PNG και .JPEG μόνο (7MB max ανά φωτογραφία)"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διατίθεται σε σημεία ρελέ"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρέπει να επαληθεύσετε την ταυτότητά σας για να αποκτήσετε πρόσβαση στο τμήμα συναλλαγών"])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε την ταυτότητά μου"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία ειδοποίησης"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θα λαμβάνετε ένα μήνυμα ηλεκτρονικού ταχυδρομείου όταν δημιουργείται μια εντολή πώλησης."])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θα λαμβάνετε ένα μήνυμα ηλεκτρονικού ταχυδρομείου όταν δημιουργείται μια εντολή αγοράς."])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λαμβάνετε ένα μήνυμα ηλεκτρονικού ταχυδρομείου όταν δημιουργείται μια παραγγελία."])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για να δημιουργήσετε μια ειδοποίηση, μεταβείτε στη σελίδα του προϊόντος της επιλογής σας."])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμές (προαιρετικά)"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η ειδοποίησή σας έχει δημιουργηθεί"])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα δημιουργίας της ειδοποίησής σας"])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η τιμή σας πρέπει να είναι μεταξύ 50 και 30000 EUR ή 0 EUR"])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παύση αυτής της ειδοποίησης"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενεργοποιήστε ξανά αυτή την ειδοποίηση"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απενεργοποιημένο"])},
      "blocktrade_link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιγράψτε το σύνδεσμο για να μοιραστείτε την εντολή σας Blocktrade"])},
      "blocktrade_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το blocktrade σας είναι διαθέσιμο για μέγιστο χρονικό διάστημα 2 ωρών. Για να μοιραστείτε αυτή την εντολή ιδιωτικής πώλησης, αντιγράψτε τον σύνδεσμο κάνοντας κλικ στο παραπάνω κουμπί."])},
      "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο σύνδεσμος Blocktrade έχει αντιγραφεί σωστά"])},
      "wrong_value_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε ελέγξτε την εισαγωγή σας"])},
      "bottle_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμή ανά μονάδα (50-30000)"])},
      "package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαστάσεις του πακέτου"])},
      "add_bottle_basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ένα μπουκάλι"])},
      "choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία λήξης"])},
      "duplicate_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν μπορείτε να προσθέσετε την ίδια αναφορά δύο φορές"])},
      "maximum_sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε φτάσει στη μέγιστη ποσότητα που επιτρέπεται για μία και μόνο πώληση"])},
      "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ένα επιπλέον δέμα"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμοκατάλογος"])},
      "min_pictures_required_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρέπει να προσθέσετε τουλάχιστον"])},
      "min_pictures_required_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" φωτογραφίες"])},
      "storage_subscription_information_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα τέλη υπολογίζονται αναλογικά μέχρι το τέλος του μήνα και περιλαμβάνουν τον επόμενο μήνα. Στη συνέχεια, θα χρεώνεστε κάθε 1η του μήνα για"])},
      "storage_enter_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέλος εισόδου στην αποθήκη"])},
      "storage_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκη φρέσκων προϊόντων"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορικές τιμές"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ένα μπουκάλι"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα γραφήματα είναι μόνο για ενημέρωση. Δεν αναλαμβάνουμε καμία ευθύνη για τυχόν σφάλματα."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα ιστορικά δεδομένα μας συγκεντρώνουν"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["πωλήσεις"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["πώληση"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["από το"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["διαφορετικές πηγές."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πηγή: Η ιστοσελίδα του Χ.Α."])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νόμισμα"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέλη"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος όρος"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κορυφή"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βάση 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Γραμμικό διάγραμμα"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διάγραμμα κεριών"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλογές διαγράμματος"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["το"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόδοση"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["από το"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι τιμές εμφανίζονται με καθυστέρηση"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μήνας"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν διαθέσιμα ιστορικά δεδομένα τιμών. Εάν δεν τα έχουμε συμπεριλάβει ακόμη, παρακαλούμε επικοινωνήστε με την υποστήριξη για να τα προσθέσουμε."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχουσα προβολή"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθημερινά"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μηνιαία"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετήσια"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εβδομαδιαία"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συγκρίνετε με"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχουν επιλεγεί φιάλες"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το μπουκάλι είναι ήδη στο διάγραμμα"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε φτάσει στο όριο των 6 φιαλών για το διάγραμμα"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή σύνθετου"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορά σφάλματος"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος σφάλματος"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρατηρήσεις"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προαιρετικό"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιγράψτε το πρόβλημα με μεγαλύτερη ακρίβεια εδώ..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στιγμιότυπα οθόνης / εικόνες"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 εικόνες το πολύ."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή"])}
    },
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λεπτομέρειες παράδοσης"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το αίτημά σας ελήφθη υπόψη"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία κυκλοφορίας"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμές εξόδου"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιστοιχίες"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιστοίχιση αγοραστή"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιστοίχιση πωλητή"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγοράστε"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποδοχή και πώληση"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Δείτε τις τρέχουσες συναλλαγές μου ", _interpolate(_named("num_transac"))])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση φωτογραφιών"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εντολή αγοράς μου"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολή πώλησης"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγοραστής"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε εξέλιξη"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολή αγοράς"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντισυμβαλλόμενος"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εντολή πώλησής μου"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν αντιστοιχίες για εμφάνιση."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία λογαριασμού και λειτουργίες διαθέσιμες σε όλους"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαρακτηριστικά"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μη ελεγμένος λογαριασμός"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαληθευμένος λογαριασμός"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δωρεάν και απεριόριστη πλήρης πρόσβαση"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορικές τιμές"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγμένη απεικόνιση"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναβολή 15 μηνών"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναβολή 12 μηνών"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαρτοφυλάκιο"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναβαλλόμενη αποτίμηση 15 μηνών"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12μηνη αναβαλλόμενη αποτίμηση"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 μοναδικοί δείκτες"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 μοναδικοί δείκτες"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βάση δεδομένων"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 tickers ανά ημέρα"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συναλλαγές"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή ενεργών εντολών"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει συναλλακτική πρόσβαση"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η επαλήθευση ταυτότητας είναι δωρεάν για τους ιδιώτες και υπόκειται σε τέλος για τους επαγγελματίες. KYC που πραγματοποιείται από τον πάροχο υπηρεσιών πληρωμών Mangopay."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απεριόριστη πλήρης πρόσβαση"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντίγραφο ταυτότητας"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχείο"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγωγή αρχείου CSV ή Excel"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπουκάλι"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε όλα"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποεπιλογή όλων"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση για ένα μπουκάλι"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαρτοφυλάκιο"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για να σας καθοδηγήσει,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανεβάστε ένα αρχείο"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ή σύρετε και αφήστε"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μόνο αρχεία CSV και XLSX"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ένα μοντέλο εισαγωγής"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στήλες"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέση περίοδος διακράτησης"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χαρτοφυλάκιο"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετονομασία"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετακίνηση σε άλλο φάκελο"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετονομασία φακέλου"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απαιτείται το όνομα του αρχείου"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο μέγιστος αριθμός χαρακτήρων δεν πρέπει να υπερβαίνει τους 30"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγωγέας"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παράμετροι"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στατιστικά στοιχεία"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στήλες πίνακα"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίνακας επιλογών"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλογές"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διπλώστε όλα τα"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ξεδιπλώστε όλα"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφάνιση πωληθέντων αποθεμάτων"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπουκάλια"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετικέτες"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσότητα"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθαρή τιμή"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακαθάριστη τιμή"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτροπή"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέλη"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τοποθεσία αποθήκευσης"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holding"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνημμένα αρχεία"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πού να αγοράσετε"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη αρχείου"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ένα μπουκάλι"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα πατρικού φακέλου"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσθετες στήλες"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στήλη παραγγελίας"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα μπουκάλια μου"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία αγοράς"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη επιλογών τιμής"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία πώλησης"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία ανταλλαγής"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πώληση"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγοράστε"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν μπουκάλια που να ανταποκρίνονται στην αναζήτησή σας."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε το γονικό φάκελο"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα αρχείου"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία λογαριασμού"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή φιάλης"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιγραφή"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχον"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπουκάλια που κατέχονται"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπουκάλια στην κατοχή σας"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μοναδικές αναφορές"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπουκάλια που κατέχετε πολλαπλασιασμένα με την αντίστοιχη τιμή αγοράς"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπουκάλια ιδιοκτησίας πολλαπλασιασμένα με την αγοραία αξία"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus valeur en cours, διαφορά μεταξύ της αγοραίας αξίας και της τιμής αγοράς πολλαπλασιασμένη επί την ποσότητα"])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχουσα απόδοση"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχουσα ετήσια απόδοση"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέση τιμή όλων των φιαλών"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσος χρόνος παραμονής της συμμετοχής ενός αγοραστή στο χαρτοφυλάκιο"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα μπουκάλια που αγοράσατε μέχρι στιγμής"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι φιάλες σας που έχουν πωληθεί μέχρι στιγμής"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνολικές δαπάνες μέχρι σήμερα"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνολικές πωλήσεις μέχρι σήμερα"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συσσωρευμένο κέρδος από την πρώτη ημέρα μέχρι σήμερα"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πραγματοποιημένο κέρδος κατά την επιλεγμένη περίοδο"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η απόδοση υπολογίζεται από την πρώτη ημέρα (λαμβάνονται υπόψη μόνο οι πωλούμενες φιάλες)"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πραγματική ετήσια απόδοση (πωληθείσες φιάλες)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μοναδικές αναφορές"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχική θέση αξίας"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχουσα απόδοση"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετήσια απόδοση"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορική ετήσια απόδοση"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορική πραγματοποιηθείσα απόδοση"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέση τιμή"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορία"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπουκάλια που αγοράζονται"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker μοναδικό"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποτίμηση"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κρυφό κέρδος"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μπουκάλια που πωλούνται"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνολικές δαπάνες"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνολικό πραγματοποιηθέν κέρδος"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πραγματοποιημένο κέρδος (περίοδος)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορική πραγματοποιηθείσα απόδοση"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνολικές πωλήσεις"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία αγοράς/πώλησης"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθαρή τιμή"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι τιμές δεν συμπεριλαμβάνονται."])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμές με αμοιβές"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμές με ΦΠΑ"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Καθαρή τιμή"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακαθάριστη τιμή αγοράς"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ακαθάριστη τιμή πώλησης"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τοποθεσία αποθήκευσης"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσότητα"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγοράζοντας"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πωλήσεις"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λεπτομέρειες αναφοράς"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το αρχείο;"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχείο Excel"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμβουλές για το αρχείο σας excel"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εμφανιζόμενες στήλες"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλέξτε όλα"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρησιμοποιούμενος χώρος"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία λογαριασμού"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθέστε ένα μπουκάλι"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισαγωγή αρχείου"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχουσα φιάλη"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχουσες φιάλες"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχουσα αναφορά"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχουσες αναφορές"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχων φάκελος"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετακίνηση σε φάκελο"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μπουκάλια στο φάκελο"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αναφορές στο φάκελο"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετακίνηση σε φάκελο"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέλη"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμμετοχές (ημέρα)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνημμένα αρχεία"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πού να αγοράσετε"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τόπος πώλησης"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσότητα που πωλείται"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμή WAB"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βραβεία WASD"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία περιουσιακών στοιχείων"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πηγαίνετε στο φύλλο προϊόντος"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταία τιμή"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταβλητότητα"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποεπιλογή όλων"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["επιλεγμένη φιάλη(ες)"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μετακίνηση"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή φιάλης"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το χαρτοφυλάκιο είναι προς το παρόν διαθέσιμο μόνο σε υπολογιστή, αλλά σύντομα θα είναι διαθέσιμο και σε κινητά."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταβλητότητα χαρτοφυλακίου"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταβλητότητα των αλκοολούχων ποτών"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταβλητότητα αποσταγματοποιίας"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage volatility"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ηλικία μεταβλητότητας"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium χαρτοφυλακίου σύντομα"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε λεπτομέρειες"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πηγαίνετε στη σελίδα προϊόντος"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχείο που δημιουργήθηκε"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το αρχείο έχει τροποποιηθεί"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το αρχείο έχει διαγραφεί"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα δημιουργίας του αρχείου"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το μπουκάλι έχει προστεθεί"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το μπουκάλι έχει τροποποιηθεί"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το μπουκάλι έχει μετακινηθεί"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το αρχείο εισήχθη επιτυχώς"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αρχικό αρχείο :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Θα μετακινήσετε αυτόν τον φάκελο και όλα τα μπουκάλια που βρίσκονται σε αυτόν."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το αρχείο σας έχει μετακινηθεί"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αδύνατη η διαγραφή καθώς υπάρχουν μπουκάλια σε αυτόν τον φάκελο"])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστολή προσφοράς"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιτρέπονται μόνο αρχεία .jpg, .jpeg και .png με μέγεθος μικρότερο των 3Mb."])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λεπτομέρειες συναλλαγών"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνοψη παραγγελιών"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κόστος αποστολής"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόρος"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγοράστε"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πώληση"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόρριψη"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πωλητής :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέλη παράδοσης"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αμοιβές συναλλαγών"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνολικό ποσό"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμπεριλαμβανομένου του ΦΠΑ σε"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέλη παράδοσης UPS"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέθοδος πληρωμής"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέθοδος πληρωμής"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS Insurance"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατοικία"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point relais"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαθέσιμα σημεία ανάληψης"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθηκεύστε αυτή τη διεύθυνση για μελλοντικές αγορές"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συναλλαγή απέτυχε. Προσπαθήστε ξανά."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συναλλαγή ολοκληρώθηκε με επιτυχία"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η πληρωμή σας επαληθεύεται"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλώ περιμένετε μερικά δευτερόλεπτα"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη στο καλάθι"])},
    "remove_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αφαίρεση από το καλάθι"])},
    "storage_exit_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέλη εξόδου"])},
    "storage_exit_payment_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτή η λειτουργία θα προστεθεί σύντομα. Παρακαλούμε επικοινωνήστε με την υπηρεσία εξυπηρέτησης πελατών για να απελευθερώσετε τις φιάλες σας."])},
    "inssurance_global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ασφάλιση"])},
    "inssurance_global_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιλαμβάνεται ασφάλιση"])},
    "insurrance_option_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ασφαλίστε τις φιάλες μου σε περίπτωση ζημιάς"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ρυθμίσεις λογαριασμού"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση συνδρομών"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι συνδρομές μου"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέθοδος πληρωμής"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορικό τιμολογίων"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο λογαριασμός μου"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγορά και πώληση"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στοιχεία τράπεζας"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ασφάλεια και 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληροφορίες"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εταιρεία"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ευγένεια"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εθνικότητα"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ονοματεπώνυμο"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία γέννησης"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός τηλεφώνου"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διεύθυνση"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ταχυδρομικός κώδικας"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιοχή"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη άλλης διεύθυνσης παράδοσης"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διεύθυνση παράδοσης"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή διεύθυνσης παράδοσης"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διεύθυνση χρέωσης"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστέλλεται σε"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν πληροφορίες"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάτοχος λογαριασμού"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποστέλλεται στο"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλάξτε το RIB σας"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διπλή αυθεντικοποίηση"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λαμβάνετε έναν κωδικό κάθε φορά που συνδέεστε και κάθε φορά που γίνεται μια αλλαγή στο λογαριασμό σας"])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διπλή επαλήθευση ταυτότητας μέσω ηλεκτρονικού ταχυδρομείου"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διπλή επαλήθευση ταυτότητας μέσω SMS"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τελευταίες συνδέσεις"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δείτε το ιστορικό των 10 τελευταίων συνδέσεων στο λογαριασμό σας σε όλες τις συσκευές."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λάβετε το ενημερωτικό δελτίο"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός χρεωστικής κάρτας."])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προστέθηκε στις"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήγει στις"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή της τραπεζικής μου κάρτας"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχετε ακόμη καταχωρίσει μια κάρτα πληρωμής."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορίστε αυτή τη διεύθυνση ως προεπιλεγμένη"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προτιμώμενη διεύθυνση παράδοσης"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή διεύθυνσης ηλεκτρονικού ταχυδρομείου"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχουσα διεύθυνση ηλεκτρονικού ταχυδρομείου"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέα διεύθυνση ηλεκτρονικού ταχυδρομείου"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή τηλεφωνικού αριθμού"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρέχων αριθμός τηλεφώνου"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέος αριθμός τηλεφώνου"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προσθήκη διεύθυνσης παράδοσης"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εγγραφή"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εσφαλμένο ηλεκτρονικό ταχυδρομείο"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λανθασμένος αριθμός τηλεφώνου"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε ήδη αυτή τη διεύθυνση ηλεκτρονικού ταχυδρομείου"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έχετε ήδη αυτόν τον αριθμό τηλεφώνου"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δαπάνες τρέχοντος μήνα"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδρομητής από"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν είστε συνδρομητής"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ετήσιες συνδρομές"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επόμενη ημερομηνία συλλογής"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδρομή μέχρι"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξερευνήστε τις διαθέσιμες συνδρομές ιστορικών τιμών"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η συνδρομή σας θα λήξει στις"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχετική συνδρομή"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση ακύρωσης"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνέχιση της συνδρομής"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεχίστε μια συνδρομή"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η επόμενη πληρωμή θα πραγματοποιηθεί στις"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σχετική συνδρομή"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η πληρωμή θα γίνεται κάθε"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["στην τιμή των"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση της ανάκαμψης"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δέσμευση μέχρι"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μήνας"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορικές τιμές premium"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιστορικό τιμολογίων"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη όλων"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τιμές"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνδρομή"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη τιμολογίου"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επί πληρωμή"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν έχετε τιμολόγιο"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λεπτομέρειες παράδοσης"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακολούθηση πακέτου"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εκτιμώμενη παράδοση"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αριθμός συναλλαγής"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε εισάγετε τον κωδικό που λάβατε με e-mail"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρακαλούμε εισάγετε τον κωδικό που λάβατε από το τηλέφωνο"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέο ηλεκτρονικό ταχυδρομείο"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νέο τηλέφωνο"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληκτρολογήστε τον κωδικό που λάβατε με e-mail"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισάγετε τον κωδικό που λάβατε από το τηλέφωνο"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διεύθυνση τιμολογίου και παράδοσης"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι προτιμήσεις σας έχουν καταχωρηθεί με επιτυχία"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πόλη γέννησης"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα γέννησης"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ειδοποιήσεις"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία εντολής αγοράς"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία εντολής πώλησης"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία εντολής ανταλλαγής"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργία εντολής blocktrade"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένας αγοραστής απέρριψε την αντιστοίχιση"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εντολή πώλησης εν αναμονή επικύρωσης"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξουσιοδότηση λήψης SMS (επικύρωση λογαριασμού ή/και 2FA)"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προτιμήσεις επικοινωνίας"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Όνομα"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS Relay Point"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλογές αγοράς"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιλογές πωλήσεων"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορατότητα αγοράς"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ορατότητα πωλήσεων"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρες που αποκλείονται"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρες που θα συμπεριληφθούν"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιγράψτε τις αγοραστικές μου προτιμήσεις για πωλήσεις"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι αλλαγές σας έχουν ληφθεί υπόψη"])}
    },
    "update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τροποποίηση πληροφοριών λογαριασμού"])},
    "wrong_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε το όνομα χρήστη σας"])},
    "catch_update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν μπορείτε να ενημερώσετε τις πληροφορίες σας. Παρακαλούμε επικοινωνήστε με την υποστήριξη εάν το πρόβλημα εξακολουθεί να υφίσταται."])},
    "pseudo_already_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το όνομα χρήστη δεν είναι πλέον διαθέσιμο, παρακαλώ επιλέξτε ένα άλλο"])},
    "update_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τροποποίηση των πληροφοριών μου"])}
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κοινωνικά δίκτυα"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποστήριξη"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νομική διεύθυνση"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κέντρο βοήθειας"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΣΥΧΝΈΣ ΕΡΩΤΉΣΕΙΣ"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσληψη"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορά σφάλματος"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατάσταση των υπηρεσιών"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νομικές πληροφορίες"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΌΡΟΙ ΚΑΙ ΠΡΟΫΠΟΘΈΣΕΙΣ"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαχείριση cookie"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εταιρεία εγγεγραμμένη στη Γαλλία"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέλη παράδοσης"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φόροι"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αγοράστε"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόρριψη"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνοψη παραγγελιών"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πωλείται μέρος :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέλη παράδοσης"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τέλη πωλήσεων"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνολικό ποσό"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνεχιζόμενη δικαστική διαμάχη"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δηλώστε μια διαφορά"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν πραγματοποιούνται συναλλαγές."])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επαναφορά φίλτρων"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημοσιεύθηκε στις :"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τροποποιήθηκε στις :"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φίλτρο άρθρων"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν βρέθηκαν στοιχεία."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαθέσιμο ποσό"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σε αναμονή"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συνολικό ποσό"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόσυρση"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κινήσεις"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πραγματοποιηθείσα πληρωμή"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχουν συναλλαγές που να εμφανίζονται."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταφορά"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εισερχόμενη μεταφορά"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εξερχόμενη μεταφορά"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληρωμή πωλητή"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απόσυρση"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανάληψη στον τραπεζικό μου λογαριασμό"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ανάληψη των διαθέσιμων κεφαλαίων"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επιβεβαίωση ανάληψης"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η μεταφορά στον τραπεζικό σας λογαριασμό ολοκληρώθηκε με επιτυχία"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η μεταφορά στον λογαριασμό σας απέτυχε. Εάν το πρόβλημα εξακολουθεί να υφίσταται, παρακαλούμε επικοινωνήστε με την υπηρεσία εξυπηρέτησης πελατών"])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το πορτοφόλι μου (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσό που απομένει μετά τη συναλλαγή :"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πληρωμή που λαμβάνεται"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πρόσβαση στο ιστορικό των συναλλαγών μου"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μεταφέρετε χρήματα απευθείας στο πορτοφόλι σας για να αποφύγετε τα τέλη πληρωμής"])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιδιοκτήτης :"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN :"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC :"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τα κεφάλαιά σας θα είναι διαθέσιμα εντός 2 έως 3 εργάσιμων ημερών."])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Στοιχείο που αντιγράφηκε επιτυχώς"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αντιγραφή"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προμήθεια πορτοφολιού"])},
    "instant_payout_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η πληρωμή γίνεται με άμεση μεταφορά από προεπιλογή"])},
    "available_amount_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαθέσιμο ποσό για ανάληψη :"])},
    "banking_account_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τραπεζικός λογαριασμός με κατάληξη :"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προηγμένα φίλτρα"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φίλτρο"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για αναζητήσεις χωρίς λέξεις-κλειδιά απαιτούνται 2 φίλτρα"])}
  },
  "ratings": {
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αξιολογήσεις"])},
    "ratings_phone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αξιολογήσεις :"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σημείωση"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περιγραφή"])},
    "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η περιγραφή μπορεί να περιέχει έως και 500 χαρακτήρες"])},
    "create_error_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελέγξτε ότι η βαθμολογία σας είναι μεταξύ 1 και 100 και ότι η περιγραφή σας έχει μήκος μικρότερο από 500 χαρακτήρες."])},
    "create_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε μια αξιολόγηση"])},
    "create_btn_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δημιουργήστε μια αξιολόγηση"])},
    "see_or_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή ή αλλαγή αξιολόγησης"])},
    "update_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αλλαγή αποτίμησης"])},
    "delete_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Διαγραφή αξιολόγησης"])},
    "rating_explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για να δημιουργήσετε μια αξιολόγηση, μεταβείτε σε μια σελίδα προϊόντος"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατηγορία, όνομα, βαθμολογία..."])},
    "description_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η περιγραφή είναι ιδιωτική. Δεν θα είναι διαθέσιμη στο κοινό"])},
    "not_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["προαιρετικό"])},
    "rating_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ελάχιστος αριθμός αποτιμήσεων που δεν επιτυγχάνεται για τη δημιουργία ενός μέσου όρου."])},
    "pseudo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Από 5 έως 50 χαρακτήρες (γίνονται δεκτά γράμματα και αριθμοί)"])},
    "empty_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεν υπάρχει ακόμη βαθμολογία για αυτό το μπουκάλι."])},
    "ranking_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το σημείο εκκίνησης για όλους τους χρήστες."])},
    "ranking_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωρίζει τους τακτικούς συνεισφέροντες."])},
    "ranking_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Υποδεικνύει προχωρημένη δέσμευση και τεχνογνωσία."])},
    "ranking_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μια πραγματική δέσμευση."])},
    "ranking_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναγνωρίζει έναν ηγετικό ρόλο στην κοινότητα."])},
    "ranking_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μόνο για πολύ ενεργούς χρήστες."])},
    "ranking_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η απόλυτη κατάταξη, για τους πιο αφοσιωμένους συνεισφέροντες."])},
    "rewards_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οι ανταμοιβές για κάθε επίπεδο θα ανακοινωθούν τον Ιανουάριο!"])},
    "pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ψευδώνυμο"])},
    "private_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κάντε το σχόλιό μου ιδιωτικό"])},
    "profil_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προφίλ"])},
    "rating_unitary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αποτίμηση"])},
    "rating_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αξιολογήσεις"])}
  },
  "storage": {
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τίτλος, αναφορά..."])},
    "invoice_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη τιμολογίου αγοράς"])},
    "check_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προβολή ή τροποποίηση της φιάλης"])},
    "store_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία αποθήκευσης"])},
    "exit_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Βγάλτε τα μπουκάλια μου"])},
    "supplier_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκη"])},
    "reference_u": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αναφορά"])},
    "reference_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["αναφορές"])}
  },
  "order_renew": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η παραγγελία σας ανανεώθηκε επιτυχώς"])}
  }
}