export default {
  "commons": {
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マッチングを断る"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータス"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売買"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スワップ"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックトレード"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポートフォリオ"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地域"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入札"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尋ねる"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ギバー"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テイカー"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーを報告する"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの種類"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在の注文"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉じる"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リセット"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品の原産地"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["続きはこちら"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["種類"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログオフ"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント作成"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索と取引"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購読"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格には消費税が含まれています。"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ナイトモード"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリアモード"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引履歴"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポートフォリオ"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マッチ"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引履歴"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント設定"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティベート"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停止中"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて削除"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現時点では通知なし"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["右クリック"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デリバリー"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引の確認"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセル"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付加価値税"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエストが多すぎる"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1日の相談限度額に達しました。これ以上の制限を避けるために、アカウントを確認してください。"])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エントリーを確認する"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーが発生しました。"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再度ログインしてください"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアクションを実行する前に、Eメールと電話を確認してください。"])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この操作を行う権限がありません"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービスをご利用いただけません。"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニュースフィード"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべての必須項目が正しく入力されていることを確認してください。"])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["言語を選択"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フランス"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英語"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スペイン語"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イタリア語"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポーランド語"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドイツ語"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チェコ語"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デンマーク"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ギリシャ語"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ハンガリー語"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本語"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韓国語"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポルトガル語"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英国"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロシア語"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スロバキア"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中国語"])}
    },
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題を報告する"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バイヤー"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り手"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コード"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コードは15分間有効です。"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記事"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフェッショナル"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォレット"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォレット"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エントリーを確認するか、カスタマーサービスまでご連絡ください。"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピー"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功したコピー"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター："])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アラート"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min ："])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大："])},
    "error_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待ち時間を超えたため、アクションはキャンセルされました。もう一度お試しください。"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貯蔵"])},
    "spirit_type_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["種類"])},
    "storage_tep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEPウェアハウス"])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このサイトを閲覧するには18歳以上である必要があります。"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年齢をご確認の上、ご入場ください。"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私は18歳以上です。"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私は18歳未満です。"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このサイトにアクセスすることにより、あなたは以下の事項に同意したものとみなされます。"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["そして"])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシーポリシー"])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["革命そのもの"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒸留酒の売買と保管のためのリファレンス"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウイスキー愛好家、コニャック・コレクター、完璧を求めるプロフェッショナルなど、Exchange Platformはあなたのためにあります。最適なセキュリティ、完全な透明性、低手数料：完全に安心してスピリッツ取引を発見してください。"])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全な取引"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.5％の手数料割引（付加価値税込み"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100％確認済みの無料アカウント"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人でもプロフェッショナルでも、何千もの蒸留酒に関する70以上の価格ソースをご覧ください！"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションなしで無料アカウントを作成する"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売買：取引は安全で透明性が高い"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引所プラットフォームは、継続的な売買を容易にします。比較しやすく透明性を保証する標準化されたリファレンスにより、利用可能なすべてのオファーに簡単にアクセスできます。お探しのものを見つけたり、興味を示したりすることができます。TEPでは、売買にかかわらず、お客様のニーズと取引相手の検索を一致させることができます。時間を節約し、取引を最適化し、常にベストプライスで取引しましょう。直感的なインターフェースと安全な取引により、TEP はスピリッツ取引を簡素化し、アマチュアからプロまで、迅速で信頼できる効率的な体験を提供します。"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトル取引所：取引相手のリスクがなく、安全で保証されている。"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEPは、当社の担保付き現金または倉庫預託ソリューションにより、完全なセキュリティでボトルを交換する可能性を提供する唯一のプラットフォームです。このシステムは、完全な保護を保証します。ボトルの各納品が確認されるまで、当社の決済サービス・プロバイダーに資金が確保されるため、取引相手のリスクはありません。この機能は、かつてない透明性と信頼性を提供することで、スピリッツ市場に革命をもたらしています。コレクターでも愛好家でも、エクスチェンジなら取引の安全性を心配することなく、コレクションの拡大や最適化が可能です。近代的で安全な環境で、安心してスピリッツを交換してください。"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックトレード：外部取引を安全に"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP独自の機能であるブロック・トレードでは、数回のクリックで売買取引を安全に行うことができます。この技術革新のおかげで、各取引に固有のリンクが生成され、信頼できる安全な執行が保証されます。売買に関わらず、Block Tradeは透明で安全なフレームワークを提供することで、外部との直接取引に伴うリスクを排除します。また、取引条件の確認、アカウントの確認、各段階でのスムーズな執行を保証する高度なツールも組み込まれています。TEPが提供する安心感と信頼性で、あなたのスピリッツを取引する新しい方法を発見してください。"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポートフォリオ管理：コレクターと投資家のための革命"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["経験豊富な方のために、取引所プラットフォームはユニークなポートフォリオ管理ツールを提供しています。この機能では、プラットフォームからのライブ価格と当社の詳細な履歴データを組み合わせて、お客様のポジションとその価値をリアルタイムで監視することができます。もう、コレクションの価値について延々と質問する必要はありません！これまでにない客観的かつ不可知論的な視点から、資産の推移をモニターし、十分な情報に基づいた意思決定を行うことができます。TEPのポートフォリオ管理ツールは、コレクションの評価や戦略的取引の計画など、お客様の経験を一変させます。"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去の価格：比類のない透明性"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEPでは、2002年までさかのぼり、あらゆる蒸留酒の最も包括的な価格履歴を編集しました！70の異なる情報源を集約し、当社独自の取引によって補完することで、実現した過去の価格について透明で不可知論的な見解を提供しています。これらのデータはすべて慎重に標準化され、18種類の通貨で利用できるため、ユーザーは世界規模で市場動向を比較・分析することができます。このユニークな機能は、コレクター、投資家、専門家にとって、購入、売却、あるいは単にスピリッツ・ポートフォリオの評価など、十分な情報に基づいた意思決定を行うために不可欠なツールです。"])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信頼の保証"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いは、スムーズな取引を保証するために、当社の決済サービスプロバイダーであるMangopayを介して行われます。"])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市場最低水準の手数料"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引手数料は、買い手と売り手ともに2.5%（消費税込み）。売買が成立した場合のみお支払いいただきます。"])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引前の匿名性"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたが個人であろうとプロであろうと、私たちのプラットフォームは、取引前の匿名性により、今日のあらゆる問題を解決します。"])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当社の決済サービス・プロバイダーによるアカウント認証前の登録管理により、偽アカウント、詐欺師、ボットがプラットフォームに入ることはありません。トレーサビリティとセキュリティは万全です。"])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様のニーズに合わせたオファー"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コミュニティに参加して、スピリッツを検索、管理、取引しましょう！"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒸留酒の種類"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトル"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ティッカー、名称、EAN、キーワード..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約なしで取引可能！"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アマチュア、コレクター、プロを問わず、取引所プラットフォームはあなたのために作られています：最適なセキュリティ、完全な透明性、低取引コスト、何千ものボトルの参照をカバーする70の履歴価格ソース。"])},
    "create_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安心してスピリッツ取引をお楽しみください。"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニュースフィード：フリー・スピリッツ・ニュースのハブ"])},
    "newsfeed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEPのニュースフィードを通じて、業界の最新ニュース、新発売、主要イベント、市場動向を常に把握することができます。この機能により、スピリッツの世界で起きているすべてのことを、このセクターで最も関連性が高く、認知度の高いサイトからの情報でリアルタイムに追うことができます。ニュースフィードは、ニュースに関する完全で不可知論的なビューを提供しますので、市場の動向を一箇所ですべて把握することができます。"])},
    "blog_and_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブログ＆レビュー：分析と発見のためのスペース"])},
    "blog_and_review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEPのブログ＆レビューで無料のテイスティング・コンテンツを探索しよう。業界の専門家や愛好家によるレビューを掲載したブログ＆レビューでは、ユニークな視点や信頼できるお勧めの情報にアクセスできます。新しいボトルの発見、市場トレンドの理解、知識の拡大など、このフィードはスピリッツの世界での体験を豊かにするためにデザインされています。"])},
    "events_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント・ブラウザー：スピリッツ・イベントのガイド"])},
    "events_browser_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEPの無料イベントブラウザを使えば、スピリッツの世界での重要なミーティングを見逃すことはもうありません。どこにいても、特別な見本市やイベントを見つけることができます。入念に更新されたセレクションにより、イベント・ブラウザは、あなたがコレクターであれ、投資家であれ、あるいは単に熱心な愛好家であれ、この分野で最も関連性の高いイベントをお届けします。お出かけの計画を立てて、スピリッツにまつわるユニークな体験をお楽しみください。"])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブログ＆レビュー"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニュースフィード"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターをリセット"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アルファベット順"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヴィル"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒸留酒の種類"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントを絞り込む"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始日"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了日"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["より"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アット"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今後のイベント"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今後のイベント"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年間契約"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年間レート"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月のレート"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年払いで1ヶ月無料"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別オファー"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロのオファー"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去のプレミアム価格"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイムラグのない完全な過去データを求めるユーザー向け。"])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月あたり"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年間"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スピリッツの種類別"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのリファレンスへのアクセス"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイムラグのない過去の価格"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高度な統計"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スピリッツを購読する"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒸留酒の選択："])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ワイン"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["補完モジュール"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["より多くのデータと真の管理ツールを求める経験豊富なコレクター向け。"])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒸留酒の分析、管理、取引を行うスペシャリストのコミュニティに参加しましょう！"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高度なポートフォリオ管理"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このオファーを選択"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バスケットに追加"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バスケット"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月の支払い"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年間支払い"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小計"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次のサンプリング"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付加価値税（20）"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いに進む"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このサブスクリプションはすでにバスケットに入っています"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたはすでに購読者です"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バスケットが空です"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購読に戻る"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡先"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課金"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引所プラットフォームでのお取引は、弊社の決済サービスプロバイダーであるMangopayを通じて行われます。"])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレジットカード"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード番号"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ペイメントカードの名前"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限 (MM/YY)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セキュリティコード"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入を確定する"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["近日公開"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い概要"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入手数料"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グローバルVAT"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購読者"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カード認証コード（カード裏面、通常3桁"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルは考慮されている"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルに失敗しました。"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引所プラットフォームへようこそ"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シームレスで安全かつ標準化された取引環境にアクセスできます。"])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインを保存"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サインイン"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをお忘れですか？"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードリセット"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの再設定方法を記載したEメールをお送りします。"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールを入力してください"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードリセット"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの再設定方法を記載したEメールをお送りします。"])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワード"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの確認"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証コード"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二重認証"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールまたは電話で受け取ったコードを入力してください。"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードは7文字以上で、少なくとも1文字は大文字でなければなりません。"])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのフィールドに正しく入力してください"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なコードを入力してください"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント作成"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["礼節"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファーストネーム"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国籍"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地域"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストリートナンバー"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヴィル"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メール"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの確認"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私は"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mangopayの。"])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私は"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["そして"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライバシーポリシー"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de エクスチェンジ・プラットフォーム."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニュースレターを購読する"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールを入力"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料アカウントを作成して次へ進む"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録完了"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証済みアカウントを取得する"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社名"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヴィル"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフェッショナルEメール"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社の種類"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社番号"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT番号"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の会社"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般情報"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYCチェック"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行詳細"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行詳細"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シームレスで安全かつ標準化された取引環境にアクセスできます。"])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用途を選択"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントタイプは、登録完了後に変更することはできません。"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフェッショナル"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人用アカウント"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証済みプロフェッショナルアカウント"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般情報"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下のフィールドに入力してください"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プライベートアカウント"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法人口座"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフェッショナルカテゴリー"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法的形態"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25％以上を保有する受益者"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25％以上を保有する他の受益者の詳細を教えてください。"])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誕生日"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["居住地"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国籍"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードは、大文字、小文字、数字、特殊文字を含む8文字以上でなければなりません。"])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールと電話番号の確認"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールで受信したコードを入力してください。"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話で受け取ったコードを入力してください。"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールが送信されました。"])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMSが送信されました。"])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コードが届きませんか？"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信先"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認済み"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認待ち"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なコードを入力してください"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["おめでとうございます、あなたはすでにこのサイトの多くの機能に無料でアクセスしています！"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全な体験を楽しむために、今すぐIDを確認しますか？"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証済みアカウントを取得する"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証済みアカウントを無料で取得"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人確認"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文書の種類を選択"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスポート"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証明書"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスポートから写真を取り込む"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証明書の両面の写真を取り込む"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを選択"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリアで読みやすいファイル"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルサイズ最大7 Mb"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG、JPEGまたはPNG形式"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証のために提出する"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均検証時間は約24時間。"])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限られた機能"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録手続きを完了し、すべての機能にアクセスしてください。"])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送付書類"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在チェック中"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルを検索し、過去の価格を見ることができる。"])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引部分へのアクセスや定期購読はまだできない。"])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbisの抄本または同等の書類で、発行後3ヶ月以内のもの。"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定款のコピー"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスポートまたは身分証明書（両面"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文書が送信されました："])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送付書類"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["却下"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否の理由"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パーソナル・アイデンティティ"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コーポレート・アイデンティティ"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["規約"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["株主宣言"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所確認"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いに進む"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効なカード番号"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効なカードの姓名"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効な有効期限"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無効なクレジットカードコード"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["企業向けKYC手数料"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["書類は正常に送信されました。検証は24時間以内に行われます。"])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受益者申告"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均検証時間はわずか数分"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリアでシャープな写真を撮る"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証明書またはパスポートが完全に見え、影や反射がなく、すべての詳細が読み取れることを確認してください。"])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["互換性のあるフォーマットで送信する："])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPEG、JPG、またはPNG形式で、情報がはっきりと見えるように十分な解像度を使用してください。"])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全文を見る"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必要なページがすべて個別に含まれていることを確認する。"])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2つの身分証明書を追加する必要があります"])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスポート用のレシートを追加する必要があります。"])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル名または1文書あたりの最大サイズ7MBを超えました。"])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行詳細"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行口座の詳細を入力すると、売上金を受け取ることができます。"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様の詳細情報は、当社のカスタマーサービス部門によって確認されます。銀行口座の詳細が確認され次第、Eメールをお送りします。"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 0000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC、ルーティング番号..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地域"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下の項目を入力してください"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投稿"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引所プラットフォームでのお取引は、弊社の決済サービスプロバイダーであるMangopayを通じて行われます。"])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行口座の詳細が保存されました"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様の銀行口座情報はすでに送信済みです。"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["おめでとうございます！アカウントが認証されました。"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルを見る"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポートフォリオにアクセス"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無制限のアクティブ・モード"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在、すべての機能にアクセスできます。"])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上記の電話番号でThe Exchange Platformからのメッセージを受信することに同意します。配信停止するにはSTOPと答えてください。"])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2FAのSMSメッセージを拒否すると、アカウントの確認ができなくなります。当社のSMS 2FAシステムで電話番号を確認した後、いつでも登録を解除することができます。"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発祥の地"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生国"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET番号"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バーコードをスキャンまたは入力"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近の検索"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて削除"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示商品"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示商品"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この検索で見つかった商品"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この検索で見つかった商品"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品検索"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取扱商品"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルをお探しですか？"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全てを見る"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テイカー"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ギバー"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックトレード"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索条件に一致するボトルは見つかりませんでした。"])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索履歴を見る"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント作成で無制限アクセス"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料登録"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この機能にはログインが必要です"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紛失したボトル"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルの名前"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必要であれば、紛失したボトルの詳細を指定してください。"])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒否の理由を記述することができます"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプション"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果が見つかりません。"])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在の注文"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトラーページ"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キーワードを組み合わせて使用することができる。"])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索は大文字と小文字を区別しない。"])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年」を指定する必要はない。"])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般的な蒸留酒のカテゴリー（ラム、ウィスキー、コニャックなど）を記入しても、結果は得られません。"])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索バーに少なくとも1つのキーワードが入っていることを確認してください。"])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スワップテイカー"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スワップギバー"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの種類"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在の注文"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向性"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成日"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い注文の変更"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り注文の変更"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックトレードの変更"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スワップ注文の変更"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除された注文を表示"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私は"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私は"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換したいもの"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文の変更に成功"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文成立"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総額："])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文数："])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトル、価格..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒絶理由"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["断る"])}
  },
  "product_page": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当するボトルは見つかりませんでした。"])},
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポートフォリオに追加"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売買"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スワップ"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックトレード"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトル情報"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ティッカー合成"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトル"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒸留酒の種類"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒸留所"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトラー"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年齢"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブランド"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヴィンテージ"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトリング年"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンテンツ"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトル番号"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生産本数"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベース"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製造方法"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒸留所の状況"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分類"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このボトルは注文できません"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ティッカー合成"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクティブ"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非アクティブ"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルを検索してチャートに追加する"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN、ティッカーまたは商品名"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お探しのボトルは見つかりませんでした。"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報源"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このリファレンスに使用できる画像はありません"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスク番号"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去の価格を見る"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ライブ注文"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトル数"])},
    "orders": {
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い注文の作成"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り注文の作成"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スワップテイカー注文の作成"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スワップギバー注文の作成"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックトレードの作成"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重量単位"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単位"])},
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文の作成"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロ"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミックス"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在は注文不可。"])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入注文"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入価格"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプション"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了日時を指定しますか？"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入注文の作成"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り注文"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重量"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["長さ"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["幅"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高さ"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真を追加"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプション"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了日時を指定しますか？"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り注文の作成"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文成立"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエストは処理されました。すぐにご連絡いたします。"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引所として守るべきルール"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不必要にオーダーブックに殺到するのを避ける"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文は実際の取引に限定してください。"])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["度重なる拒否を避ける"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マッチングを何度も拒否すると、悪意がある、または無責任であると受け取られる可能性があります。取引を完了する意思がある場合のみ、取引を行うようにしてください。"])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回復時間の尊重"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小包が時間通りに集荷されないことが度重なると、売り手は不便を強いられ、追加費用が発生する可能性がある。"])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オーダーブックの完全性を尊重する"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オーダーブックを不正に操作すること、マッチングが拒否された後に関心を無効化することなどは、欺瞞的行為と見なされます。あなたの行動が透明であることを確認してください。"])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["関連費用に備えよう"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現地の輸入税や関税がかかる場合があります。取引を行う際には、税金を支払う用意があることを確認してください。"])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正確な情報の提供"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルの説明が正確で真実であることを確認する。これは、買い手が十分な情報を得た上で判断するのに役立ちます。正面と背面の写真を掲載しましょう。"])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オファーの詳細を見る"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルを売ることを約束し、その約束を守ってください。この約束が守られない場合、売主に不利益が生じます。"])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パッケージと中身の品質保証"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["破損した小包（劣化、破損、漏出）や誤った内容物の配達が繰り返されると、お客様に影響が及びます。ボトルの梱包には十分ご注意ください。"])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配達時間の尊重"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間通りに出荷されないことが度重なると、取引エリアへのアクセスに影響が出る場合があります。遅延を避けるため、計画的にご利用ください。"])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パッケージ参照："])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チケットの解決を促進するために、ここにあなたの問題を詳述することができます。"])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小包の受け取りを確認する"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小包の受領を確認することで、商品がお客様の期待に沿うものであることを確認することになります。"])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資産"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マッチ"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待機中"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信先"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トランジット"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配信"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訴訟"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デリバリーの詳細"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追跡番号"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有料"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小包の追跡"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送の詳細を見る"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規注文を作成する前に、提案を承認または拒否してください。"])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックトレードへのリンク"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有リンク"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["決済プロバイダーMangopayによる安全な取引"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["納品書のダウンロード"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匿名取引（消費税込み3ユーロ）"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルの画像をアップロードする必要があります"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同じ画像を複数回貼ることはできません"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7MB以下の画像で、拡張子が.JPG、.JPEG、.pngのものに限ります。"])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文の有効期限は1時間です。"])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在、購入注文は受け付けていない。"])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在、売り注文は受け付けていない。"])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い手はあなたの身元を知らない"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホームコレクション"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小包はUPSでご自宅から集荷されます。"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["収集日"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最初のピックアップ時間"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終引取時間"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この注文は、お客様の国または取引先の希望と一致しません。"])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像を追加"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["またはドラッグ＆ドロップ"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".JPG、.PNG、.JPEGのみ（1枚につき最大7MBまで）"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中継ポイント"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引セクションにアクセスするには、本人確認が必要です。"])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証明書の確認"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アラートの作成"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り注文が作成されるとメールが届きます。"])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い注文が作成されるとメールが届きます。"])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文が作成されるとEメールが送信されます。"])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アラートを作成するには、ご希望の商品ページにアクセスしてください。"])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格（オプション）"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アラートが作成されました"])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アラート作成エラー"])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格は50～30000EURまたは0EURの間でなければなりません。"])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この通知を一時停止する"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアラートを再アクティブ化する"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停止中"])},
      "blocktrade_link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクをコピーしてBlocktrade注文を共有する"])},
      "blocktrade_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様のブロックトレードは最大2時間有効です。このプライベート・セール・オーダーを共有するには、上のボタンをクリックしてリンクをコピーしてください。"])},
      "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックトレードのリンクは正しくコピーされています。"])},
      "wrong_value_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力内容をご確認ください"])},
      "bottle_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単位あたりの価格 (50-30000)"])},
      "package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パッケージ寸法"])},
      "add_bottle_basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルを追加する"])},
      "choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
      "duplicate_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同じリファレンスを2回追加することはできません。"])},
      "maximum_sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一回の売買の上限数量に達しました。"])},
      "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小包の追加"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格リスト"])},
      "min_pictures_required_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["少なくとも"])},
      "min_pictures_required_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真"])},
      "storage_subscription_information_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料は月末までの日割りで計算され、翌月も含まれる。その後、毎月1日に以下の料金が引き落とされます。"])},
      "storage_enter_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入庫手数料"])},
      "storage_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生鮮倉庫"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去の価格"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルを追加する"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グラフは情報提供のみを目的としています。当社はいかなる誤りに対しても責任を負いません。"])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当社の過去データ集計"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["より"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["様々な情報源"])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソース"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通貨"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トップ"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベース"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ベース100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ラインチャート"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンドルチャート"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャートオプション"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パフォーマンス"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["より"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格は"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去の価格データはありません。サポートまでご連絡ください。"])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のビュー"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デイリー"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毎月"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年間"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウィークリー"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比較する"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトル選択なし"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このボトルはすでにチャート上にある"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャートが6本の上限に達しました。"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンポジットを見る"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーを報告する"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーの種類"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備考"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプション"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問題をより具体的に説明してください。"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スクリーンショット / 画像"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像は2枚まで。"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信"])}
    },
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デリバリーの詳細"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのリクエストは考慮されました"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リリース日"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出口価格"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マッチ"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マッチバイヤー"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マッチングセラー"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売買"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["私の", _interpolate(_named("num_transac")), " 現在の取引を見る"])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真を表示"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の買い注文"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り注文"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バイヤー"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入注文"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カウンターパーティ"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の売り注文"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示する銘柄がありません。"])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント作成と機能は誰でも利用可能"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特徴"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未監査アカウント"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証済みアカウント"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニュースフィード"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無料で無制限にフルアクセス"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去の価格"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高度な視覚化"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15ヵ月据置"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12ヵ月据置"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポートフォリオ"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15ヶ月繰延評価"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12ヶ月繰延評価"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50のユニークなティッカー"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100のユニークなティッカー"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データベース"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1日50ティッカー"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効な注文を見る"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引不可"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID認証は個人は無料、プロは有料。KYCはペイメント・サービス・プロバイダーのMangopayが行います。"])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無制限のフルアクセス"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDコピー"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSVまたはエクセルファイルのインポート"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトル"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてを選択"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて選択解除"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルの検索"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポートフォリオ"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガイド"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダウンロード"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルのアップロード"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["またはドラッグ＆ドロップ"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSVファイルとXLSXファイルのみ"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入モデル"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コラム"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均保有期間"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポートフォリオ"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リネーム"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他のフォルダへ移動"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォルダ名の変更"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル名は必須です"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大文字数は30文字以内"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インポーター"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パラメータ"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統計"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表の列"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプション表"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オプション"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて折りたたむ"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてを開く"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売られた銘柄を表示"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトル"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ティッカー"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インフォ"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネット価格"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["グロス価格"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保管場所"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保有"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アタッチメント"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入場所"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルを追加"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルを追加する"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["親フォルダ名"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["その他のコラム"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コラムオーダー"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイボトル"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入日"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格オプションの追加"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売日"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引日"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索条件に一致するボトルは見つかりませんでした。"])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["親フォルダを選択"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル名"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント作成"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルの変更"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保有ボトル"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保有ボトル"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユニークなリファレンス"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有ボトル数×購入価格"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有ボトル数×市場価値"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus valeur en cours、市場価格と購入価格の差に数量を掛けたもの。"])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のパフォーマンス"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在の年間リターン"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全ボトルの平均価格"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い手がポートフォリオに保有する平均期間"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これまでに購入したボトル"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これまでに販売したボトル"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これまでの総支出額"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これまでの総売上高"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初日から現在までの累積利益"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択した期間中の実現利益"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初日から計算されるリターン（販売されたボトルのみを考慮）"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年間実質利回り（販売本数）"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユニークなリファレンス"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初値ポジション"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のパフォーマンス"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年率リターン"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去の年間リターン"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去の実現リターン"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均価格"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歴史"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入ボトル"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ティッカー"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["潜在的利益"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売ボトル"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総支出"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総実現利益"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["実現利益（期間）"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去の実現リターン"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売総額"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売買日"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネット価格"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格は税抜です。"])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料込み価格"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付加価値税込み価格"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネット価格"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総購入価格"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総販売価格"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保管場所"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参考詳細"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このファイルを削除してもよろしいですか？"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エクセルファイル"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エクセルファイルのヒント"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示されるコラム"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてを選択"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用スペース"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント作成"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルを追加する"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルのインポート"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のボトル"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のボトル"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のリファレンス"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のリファレンス"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在の資料"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォルダへ移動"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォルダ内のボトル"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参考文献"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フォルダへ移動"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保有数（日）"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アタッチメント"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入場所"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売場所"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売数量"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WAB価格"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WASDアワード"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリー"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アセットクラス"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["製品シートへ"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終価格"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボラティリティ"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて選択解除"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択されたボトル"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移動"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルを削除"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポートフォリオは現在PCでのみ利用可能だが、近日中にモバイルでも利用可能になる予定。"])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポートフォリオのボラティリティ"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒸留酒のボラティリティ"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒸留所のボラティリティ"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヴィンテージ・ボラティリティ"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボラティリティ時代"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポートフォリオ・プレミアムは近日公開"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細を見る"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商品ページへ"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル作成"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルが変更されました"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルは削除されました"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル作成エラー"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルが追加されました"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルが変更されました"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルは移動しました"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイルは正常にインポートされました"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オリジナルファイル："])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このフォルダとその中の全てのボトルを移動することになります。"])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのファイルは移動されました"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このフォルダにボトルがあるため、削除できません。"])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オファーを送る"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3Mb以下の.jpg、.jpeg、.pngファイルのみが許可されています。"])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引の詳細"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文概要"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送料"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税金"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["断る"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り手："])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送料"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引手数料"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総額"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付加価値税込み"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPSの配送料"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS保険"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドミサイル"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポイント・リレ"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出金可能ポイント"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将来の購入のためにこのアドレスを保存する"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引に失敗しました。もう一度お試しください。"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引は正常に完了しました"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払いが確認されました"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数秒お待ちください"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バスケットに追加"])},
    "remove_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バスケットから削除"])},
    "storage_exit_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出口手数料"])},
    "storage_exit_payment_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この機能は近日公開予定です。ボトルのリリースについては、カスタマーサービスまでお問い合わせください。"])},
    "inssurance_global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保険"])},
    "inssurance_global_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保険を含む"])},
    "insurrance_option_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルが破損した場合の保険"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント設定"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購読管理"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の購読"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インボイス履歴"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイアカウント"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売買"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行詳細"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全性と2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社概要"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["礼節"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国籍"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファーストネーム"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生年月日"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電子メール"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵便番号"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヴィル"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地域"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別の配送先を追加する"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送先"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送先住所の削除"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求先住所"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信先"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報なし"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["口座保有者"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIBを変更する"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二重認証"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインするたびに、またアカウントに変更が加えられるたびにコードを受け取ります。"])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールによる二重認証"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMSによる二重認証"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新の接続"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのデバイスで、アカウントへの過去10回の接続履歴を見ることができます。"])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニュースレターを受け取る"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デビットカード番号"])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加日"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編集"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効期限"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行カードの変更"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["まだ支払いカードを登録していません。"])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このアドレスをデフォルトに設定する"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご希望の配送先"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールアドレスの変更"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のメールアドレス"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいメールアドレス"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号の変更"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在の電話番号"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい電話番号"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送先住所の追加"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメール"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号の間違い"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでにこのメールアドレスをお持ちの方"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["この電話番号はすでにお持ちです"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当月の支出"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加入者数"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたは購読者ではありません"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年間契約"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次回の収集日"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購読期間"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録解除"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用可能なヒストリカル・プライス・サブスクリプションを調べる"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録解除"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプションの有効期限は"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプション"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンセルの確認"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購読の再開"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購読を続ける"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次回の支払いは"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプション"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いは"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回復の確認"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コミット期限"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アンズ"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去のプレミアム価格"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インボイス履歴"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてダウンロード"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータス"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サブスクリプション"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インボイスのダウンロード"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有料"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求書はありません"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デリバリーの詳細"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パッケージの追跡"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送の目安"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引数"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールで受信したコードを入力してください。"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話で受け取ったコードを入力してください。"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい電子メール"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しい電話"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールで受信したコードを入力してください"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話で受け取ったコードを入力"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求書と配送先住所"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様の設定が正常に登録されました"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生地"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生国"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い注文の作成"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り注文の作成"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スワップ注文の作成"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックトレード注文の作成"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バイヤーがマッチングを断る"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検証待ちの売却注文"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMSの受信を許可する（アカウント認証および/または2FA）"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コミュニケーションの好み"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPSリレーポイント"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入オプション"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売オプション"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入の可視性"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売の可視性"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除外する国"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["対象国"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売用の購入希望をコピーする"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様の変更は考慮されています"])}
    },
    "update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント情報の変更"])},
    "wrong_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー名をご確認ください"])},
    "catch_update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報を更新できません。問題が解決しない場合は、サポートにお問い合わせください。"])},
    "pseudo_already_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このユーザーネームは使用できなくなりました。"])},
    "update_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["情報の変更"])}
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソーシャルネットワーク"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サポート"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リーガル"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヘルプセンター"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["よくある質問"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["採用情報"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バグを報告する"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス状況"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["法的情報"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引条件"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クッキー管理"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フランス法人"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送料"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税金"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["断る"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文概要"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売部分："])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送料"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["販売手数料"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総額"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["センス"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中の訴訟"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紛争を宣言する"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引は行われない。"])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターをリセット"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開日："])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更日："])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記事を絞り込む"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["見つかりませんでした。"])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引可能量"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総額"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出金"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動き"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引は表示されない。"])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送金"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送金"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送金"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り手の支払い"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出金"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行口座への引き出し"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用可能資金の引き出し"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出金確認"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銀行口座への送金が完了しました。"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様の口座への送金に失敗しました。問題が解決しない場合は、カスタマーサービスまでご連絡ください。"])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイウォレット (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引後の残額："])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引履歴にアクセスする"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い手数料を避けるため、直接ウォレットに送金する。"])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オーナー："])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN ："])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC ："])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資金は2～3営業日以内に利用可能になります。"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピーに成功した要素"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピー"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォレットのプロビジョニング"])},
    "instant_payout_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いはデフォルトで即時送金"])},
    "available_amount_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き出し可能額："])},
    "banking_account_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".で終わる銀行口座"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高度なフィルター"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キーワードなしの検索には2つのフィルターが必要です。"])}
  },
  "ratings": {
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価"])},
    "ratings_phone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["格付け："])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明"])},
    "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明文は500文字まで"])},
    "create_error_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価が1～100の間であることと、説明が500文字以内であることを確認してください。"])},
    "create_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価の作成"])},
    "create_btn_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価の作成"])},
    "see_or_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レーティングの確認・変更"])},
    "update_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価額の変更"])},
    "delete_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価を削除"])},
    "rating_explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価を作成するには、商品ページにアクセスしてください。"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カテゴリー、名前、スコア..."])},
    "description_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["説明は非公開。一般に公開されることはありません。"])},
    "not_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任意"])},
    "rating_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均を算出するための最低評価回数に達していない。"])},
    "pseudo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5～50文字（英数字可）"])},
    "empty_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このボトルに対する評価はまだありません。"])},
    "ranking_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのユーザーの出発点です。"])},
    "ranking_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常連の投稿者を表彰。"])},
    "ranking_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高度なコミットメントと専門知識を示す。"])},
    "ranking_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["真のコミットメント"])},
    "ranking_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コミュニティにおけるリーダー的役割を認識。"])},
    "ranking_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非常にアクティブなユーザーのみ。"])},
    "ranking_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最も熱心な貢献者のための究極のランク。"])},
    "rewards_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各レベルの報酬は1月に発表される！"])},
    "pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネーム"])},
    "private_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コメントを非公開にする"])},
    "profil_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィール"])},
    "rating_unitary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評価"])},
    "rating_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["格付け"])}
  },
  "storage": {
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貯蔵"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトル、参照..."])},
    "invoice_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購入請求書のダウンロード"])},
    "check_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルの確認・変更"])},
    "store_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保管日"])},
    "exit_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ボトルを取り出す"])},
    "supplier_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["倉庫"])},
    "reference_u": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参考"])},
    "reference_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参考文献"])}
  },
  "order_renew": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご注文が更新されました"])}
  }
}