export default {
  "commons": {
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvis matchet"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udløbsdato"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køb og sælg"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portefølje"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bud"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spørg"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tager"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporter en fejl"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type af konto"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordrer"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle ordrer"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mængde"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage til"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luk"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstilling"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansøg"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktets oprindelse"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå videre til"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbindelse"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log af"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en konto"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søgning og handel"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementer"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisen inkluderer moms"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nattilstand"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klar tilstand"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionshistorik"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portefølje"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tændstikker"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionshistorik"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoindstillinger"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiveret"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiveret"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikationer"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet alle"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen notifikationer i øjeblikket"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan ikke højreklikke"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levering"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrol af transaktionen"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuller"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOMS"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I alt"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kommer med for mange anmodninger"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nået din daglige konsultationsgrænse. Tjek din konto for at undgå yderligere begrænsninger."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjek din indtastning:"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl, prøv igen senere"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log venligst ind igen"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft venligst din e-mail og telefon, før du udfører denne handling."])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er ikke autoriseret til at udføre denne handling"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjenesten er ikke tilgængelig, kontakt venligst kundeservice"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyhedsfeed"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjek venligst, at alle obligatoriske felter er udfyldt korrekt."])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg et sprog"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fransk"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engelsk"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spansk"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiensk"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polsk"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tysk"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjekkisk"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dansk"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Græsk"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungarsk"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japansk"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koreansk"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugisisk"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STORBRITANNIEN"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russisk"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakisk"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinesisk"])}
    },
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporter et problem"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køber"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sælger"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kode sendt"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemærk, at koden er gyldig i 15 minutter."])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikler"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionel"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuel"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegnebog"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tegnebog"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spar"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjek venligst din indtastning eller kontakt kundeservice"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopi"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vellykket kopi"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter :"])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advarsler"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min :"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max :"])},
    "error_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlingen er blevet annulleret, da ventetiden er overskredet. Prøv venligst igen"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opbevaring"])},
    "spirit_type_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "storage_tep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP-lager"])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal være over 18 år for at besøge denne side"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjek venligst din alder for at komme ind."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg er over 18 år"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg er under 18 år"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ved at gå ind på dette websted accepterer du"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vilkår og betingelser for brug"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["og"])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politik for beskyttelse af personlige oplysninger."])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En revolution i sig selv"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencen til køb, salg og opbevaring af din spiritus"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uanset om du er whiskyelsker, cognacsamler eller professionel på jagt efter perfektion, er The Exchange Platform noget for dig. Optimal sikkerhed, total gennemsigtighed og lave gebyrer: Oplev spiritushandel med fuldstændig ro i sindet."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikre transaktioner"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reducerede gebyrer 2,5 % inkl. moms"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% verificerede gratis konti"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uanset om du er privatperson eller professionel, kan du udforske vores samling af over 70 priskilder på tusindvis af spiritusreferencer!"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret din gratis konto uden abonnement"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køb og salg: Dine transaktioner er sikre og gennemsigtige"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Børsens platform gør det nemt at købe og sælge løbende. Få nem adgang til alle tilgængelige tilbud takket være vores standardiserede referencer, som gør det nemt at sammenligne og garanterer gennemsigtighed. Find det, du leder efter, eller udtryk din interesse: TEP giver dig mulighed for at matche dine behov med modparternes søgninger, uanset om du køber eller sælger. Spar tid, optimer dine transaktioner, og handl altid til den bedste pris. Med en intuitiv grænseflade og sikre transaktioner forenkler TEP handel med spiritus og tilbyder en hurtig, pålidelig og effektiv oplevelse for både amatører og professionelle."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flaskebørs: sikker og garanteret uden modpartsrisiko"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP er den eneste platform, der giver mulighed for at bytte flasker i fuld sikkerhed takket være vores løsninger med sikkerhedsstillelse i form af kontanter eller lagerindskud. Dette system garanterer total beskyttelse: Der er ingen modpartsrisiko, fordi pengene er sikret hos vores betalingstjenesteudbyder, indtil de respektive leveringer af flaskerne er bekræftet. Denne funktion revolutionerer spiritusmarkedet ved at give en hidtil uset gennemsigtighed og pålidelighed. Uanset om du er samler eller kender, giver Exchange dig mulighed for at udvide eller optimere din samling uden at skulle bekymre dig om transaktionssikkerheden. Byt din spiritus med fuldstændig ro i sindet i et moderne og sikkert miljø."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade: Sikr dine eksterne transaktioner med lethed"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade, en eksklusiv TEP-funktion, giver dig mulighed for at sikre dine købs-/salgstransaktioner med blot nogle få klik. Takket være denne innovation genereres der et unikt link for hver transaktion, hvilket garanterer en pålidelig og sikker udførelse. Uanset om du køber eller sælger, eliminerer Block Trade de risici, der er forbundet med direkte eksterne udvekslinger, ved at tilbyde en gennemsigtig og sikker ramme. Den indeholder også avancerede værktøjer til at bekræfte transaktionsvilkår, verificere konti og sikre en problemfri udførelse i alle faser. Oplev en ny måde at handle med spiritus på med den tryghed og pålidelighed, som TEP tilbyder."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porteføljestyring: en revolution for samlere og investorer"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For de mere erfarne tilbyder The Exchange Platform et unikt værktøj til porteføljestyring. Denne funktion giver dig mulighed for at overvåge dine positioner og deres værdi i realtid ved hjælp af live-priser fra platformen kombineret med vores detaljerede historiske data. Ikke flere endeløse spørgsmål om værdien af din samling! Med en hidtil uset objektiv og agnostisk visning kan du overvåge udviklingen i dine aktiver og træffe informerede beslutninger. Uanset om du evaluerer din samling eller planlægger strategiske transaktioner, forvandler TEP's porteføljestyringsværktøj din oplevelse ved at give total gennemsigtighed og optimal kontrol over din spiritus."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiske priser: uovertruffen gennemsigtighed"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hos TEP har vi samlet den mest omfattende prishistorik for alle mulige former for spiritus, med data helt tilbage til 2002! Takket være sammenlægningen af 70 forskellige kilder, suppleret med vores egne transaktioner, tilbyder vi et gennemsigtigt og agnostisk billede af tidligere realiserede priser. Alle disse data er omhyggeligt standardiseret og gjort tilgængelige i 18 forskellige valutaer, så vores brugere kan sammenligne og analysere markedstendenser på globalt plan. Denne unikke funktionalitet er et uundværligt værktøj for samlere, investorer og fagfolk, der ønsker at træffe informerede beslutninger, hvad enten det drejer sig om køb, salg eller blot evaluering af deres spiritusportefølje."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En garanti for tillid"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalinger foretages via vores betalingstjenesteudbyder Mangopay for at sikre smidige transaktioner, ingen risiko for at penge forsvinder, du sender dem aldrig til modparten."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laveste gebyrer på markedet"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi har de laveste gebyrer på markedet, 2,5 % inkl. moms for køber og sælger, ingen noterings- eller reservationsgebyrer. Du betaler kun, hvis der er en effektiv transaktion."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymitet før transaktionen"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uanset om du er privatperson eller professionel, er vores platform løsningen på alle nutidens problemer takket være dens anonymitet før transaktionen."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takket være vores betalingstjenesteudbyders registreringskontrol før kontovalidering kan ingen fantomkonto, svindler eller bot komme ind på platformen. Sporbarheden og sikkerheden er total."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et tilbud skræddersyet til dine behov"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltag i et fællesskab for at finde, administrere og handle med din spiritus!"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type af spiritus"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flaske"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker, navn, EAN, nøgleord..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg inden for"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handel er tilgængelig uden abonnement!"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uanset om du er amatør, samler eller professionel, er The Exchange Platform skabt til dig: optimal sikkerhed, total gennemsigtighed og lave transaktionsomkostninger med 70 historiske priskilder, der dækker tusindvis af flaskereferencer."])},
    "create_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplev handel med spiritus med fuldstændig ro i sindet."])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed: din hub for nyheder om fri spiritus"])},
    "newsfeed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold dig opdateret med de seneste branchenyheder, nye udgivelser, større begivenheder og markedstendenser via TEP's Newsfeed. Denne funktion giver dig mulighed for at følge med i alt, hvad der sker i spiritusverdenen i realtid, med information fra de mest relevante og anerkendte websteder i sektoren. Newsfeed giver dig et komplet, agnostisk overblik over nyhederne, så du kan holde dig ajour med markedsudviklingen på ét sted."])},
    "blog_and_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog & Review: din plads til analyse og opdagelse"])},
    "blog_and_review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udforsk gratis smagsindhold med TEP's Blog & Review. Med anmeldelser fra brancheeksperter og entusiaster giver Blog & Review dig adgang til unikke perspektiver og pålidelige anbefalinger. Uanset om du ønsker at opdage nye flasker, forstå markedstendenser eller udvide din viden, er dette feed designet til at berige din oplevelse af spiritusverdenen."])},
    "events_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begivenhedsbrowser: din guide til spiritusbegivenheder"])},
    "events_browser_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Med TEP's gratis begivenhedsbrowser går du aldrig glip af et vigtigt møde i spiritusverdenen. Find ud af mere om eksklusive messer og arrangementer, uanset hvor du er. Takket være et omhyggeligt opdateret udvalg giver Events Browser dig de mest relevante begivenheder i sektoren, uanset om du er samler, investor eller blot entusiast. Planlæg dine udflugter og nyd unikke oplevelser omkring spiritus."])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog og anmeldelse"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyhedsfeed"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begivenheder"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil filtre"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorter efter alfabetisk rækkefølge"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byen"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datoer"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Måned"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type af spiritus"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer begivenheder"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdato"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slutdato"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fra"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["på"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommende begivenheder"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kommende begivenheder"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årlige abonnementer"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årlige priser"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedlige priser"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 måned gratis ved årlig betaling"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Særligt tilbud"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionelt tilbud"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiske priser på premium"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For brugere, der ønsker komplette historiske data uden tidsforskydning."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pr. måned"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pr. år"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["efter type af spiritus"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgang til alle referencer"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiske priser uden tidsforskydning"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerede statistikker"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonner på en spiritus"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valg af spiritus :"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vin"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andet"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplerende modul"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For erfarne samlere, der ønsker flere data og et reelt administrationsværktøj."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deltag i et fællesskab af specialister for at analysere, administrere og handle med din spiritus!"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanceret porteføljestyring"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg dette tilbud"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læg i kurv"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurv"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedlig betaling"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årlig betaling"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-total"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Næste prøveudtagning"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I alt"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOMS (20%)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt til betaling"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletning"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette abonnement ligger allerede i din kurv"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er allerede abonnent"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din kurv er tom"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage til abonnementer"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktoplysninger"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturering"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metode til betaling"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktioner på The Exchange Platform udføres via vores betalingstjenesteudbyder Mangopay."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditkort"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kortnummer"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på betalingskort"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udløbsdato (MM/ÅÅ)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikkerhedskode"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Færdiggør dit køb"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommer snart"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt over betalinger"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køb af kommission"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global moms"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnent"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kortbekræftelseskoden (på bagsiden af kortet, normalt 3 cifre)"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er taget højde for annulleringen"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbestilling mislykkedes, kontakt venligst kundeservice"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkommen til udvekslingsplatformen"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få adgang til et problemfrit, sikkert og standardiseret handelsmiljø."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem mit login"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ind"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du glemt din adgangskode?"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstilling af adgangskode"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du vil modtage en e-mail med instruktioner om, hvordan du nulstiller din adgangskode."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast venligst din e-mail"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstilling af adgangskode"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du vil modtage en e-mail med instruktioner om, hvordan du nulstiller din adgangskode."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny adgangskode"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft adgangskode"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræftelseskode"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobbelt autentificering"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast venligst den kode, du har modtaget via e-mail eller telefon"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din adgangskode skal indeholde 7 tegn med mindst ét stort bogstav."])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udfyld venligst alle felter korrekt"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast venligst en gyldig kode"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en konto"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høflighed"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fødselsdato"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalitet"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gadenummer"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postnummer"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byen"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft adgangskode"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg accepterer"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vilkår og betingelser for brug"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["af Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg accepterer"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vilkår og betingelser for brug"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["og"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privatlivspolitik"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Børsens platform."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld dig vores nyhedsbrev"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast din e-mail"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret min gratis konto og fortsæt"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuldfør min registrering"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få en verificeret konto"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virksomhedens navn"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuldstændig adresse"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postnummer"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byen"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionel e-mail"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type af virksomhed"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virksomhedens nummer"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momsnummer"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min virksomhed"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generelle oplysninger"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræftelse 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC-tjek"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankoplysninger"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplysninger om"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankoplysninger"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få adgang til et problemfrit, sikkert og standardiseret handelsmiljø."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg din anvendelse"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontotypen kan ikke ændres, når registreringen er gennemført."])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkeltpersoner"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionel"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto til personlig brug"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificeret professionel konto"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generelle oplysninger"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udfyld venligst alle felter nedenfor"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat konto"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virksomhedskonto"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionel kategori"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridisk form"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reelle ejere, der ejer mere end 25 % ?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv venligst oplysninger om andre reelle ejere, der ejer mere end 25 %."])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Født den"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bo i"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" og nationalitet"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskoden skal indeholde mindst 8 tegn, herunder et stort bogstav, et lille bogstav, et tal og et specialtegn."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræftelse af din e-mail og dit telefonnummer"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast venligst den kode, du har modtaget via e-mail"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast venligst den kode, du har modtaget via telefon"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er sendt en e-mail til dig."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er sendt en sms til dig."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Har du ikke modtaget koden?"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send til"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræftet"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afventer bekræftelse"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast venligst en gyldig kode"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillykke, du har allerede gratis adgang til mange af hjemmesidens funktioner!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du gerne have din identitet verificeret nu for at få den fulde oplevelse?"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få en verificeret konto"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få en gratis verificeret konto"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræftelse af din identitet"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg typen af dokument"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitetskort"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer et foto fra dit pas"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer et foto af begge sider af dit identitetskort"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg en fil"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tydelig og læsbar fil"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filstørrelse max. 7 Mb"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG-, JPEG- eller PNG-format"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsend til verifikation"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den gennemsnitlige verifikationstid er omkring 24 timer."])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begrænset funktionalitet"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemfør registreringsprocessen og få adgang til alle funktionerne."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendte dokumenter"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjekkes i øjeblikket"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kan søge efter flasker og se historiske priser."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kan endnu ikke få adgang til transaktionsdelen eller tegne abonnementer."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendt på"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis-ekstrakt eller tilsvarende dokument, der er mindre end tre måneder gammelt"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopi af vedtægter"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas eller identitetskort, begge sider"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentet er sendt:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenter sendt:"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvist"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsag til afslag:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personlig identitet"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virksomhedens identitet"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedtægter"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erklæring fra aktionærerne"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræftelse af adresse"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt til betaling"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldigt kortnummer"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn og efternavn på ugyldigt kort"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig udløbsdato"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig kreditkortkode"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC-gebyrer for virksomheder"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenterne er blevet sendt med succes. Validering vil finde sted inden for 24 timer."])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erklæring om reelle ejere"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den gennemsnitlige verifikationstid er kun få minutter"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag klare, skarpe billeder:"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for, at identitetskortet eller passet er fuldt synligt, uden skygger eller refleksioner, og at alle detaljer er læselige."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send i et kompatibelt format:"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brug et JPEG-, JPG- eller PNG-format med tilstrækkelig opløsning til, at oplysningerne kan ses tydeligt."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se det fulde dokument:"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for, at alle de nødvendige sider er inkluderet separat."])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal tilføje 2 identitetsbeviser"])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal tilføje en kvittering for passet"])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit filnavn eller den maksimale størrelse på 7 MB pr. dokument er blevet overskredet."])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankoplysninger"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast dine bankoplysninger for at modtage pengene fra dit salg."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine oplysninger vil blive valideret af vores kundeservice. Du vil modtage en e-mail, når dine bankoplysninger er blevet valideret."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 0000 000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, routing-nummer..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udfyld venligst"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indsend"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktioner på The Exchange Platform udføres via vores betalingstjenesteudbyder Mangopay."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine bankoplysninger er blevet gemt"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine bankoplysninger er allerede sendt"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillykke med din konto! Din konto er nu verificeret."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemse flasker"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få adgang til min portefølje"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubegrænset aktiv tilstand"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan nu få adgang til alle funktioner."])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg accepterer at modtage beskeder fra The Exchange Platform på ovenstående telefonnummer. Der kan forekomme datagebyrer, svar STOP for at afmelde."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du afviser 2FA SMS-beskeder, kan du ikke bekræfte din konto. Du kan altid afmelde dig, når du har bekræftet dit telefonnummer via vores SMS 2FA-system."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fødested"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fødselsland"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET-nummer"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan eller skriv stregkoden"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste søgninger"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet alle"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vist produkt"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udstillede produkter"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt fundet med denne søgning"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkter fundet med denne søgning"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produkt fundet"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fundne produkter"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mangler du en flaske?"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer efter"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se alle sider"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køb"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sælg"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tager"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokhandel"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen flasker, der matchede din søgning."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se søgehistorik"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en konto for at få ubegrænset adgang"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrer dig gratis"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login påkrævet for denne funktion"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manglende flaske"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på flasken"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv om nødvendigt detaljerne for den manglende flaske"])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan beskrive, hvor årsagerne til afslag"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valgfrit"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen resultater fundet."])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle ordrer"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapperiets side"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan bruge kombinationer af søgeord."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søgningen er ikke følsom over for store og små bogstaver."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke nødvendigt at angive \"år\"."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At skrive en generisk spirituskategori (rom, whisky, cognac osv.) vil ikke give nogen resultater."])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjek venligst, at du har mindst ét søgeord i søgefeltet."])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Taker"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Giver"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type af konto"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle ordrer"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retning"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for oprettelse"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priserne"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mængde"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændre købsordre"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændre salgsordre"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændre blokhandel"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændre swap-ordre"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis slettede ordrer"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg vil gerne modtage en"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg ejer en"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["til gengæld for"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvad jeg gerne vil bytte til"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre ændret med succes"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre oprettet med succes"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlet beløb :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal ordrer :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel, pris..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsag til afslag"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvis"])}
  },
  "product_page": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen flaske til denne reference."])},
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj til portefølje"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køb/salg"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokhandel"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information om flasker"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker-sammensætning"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type af spiritus"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destilleri"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapperi"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alder"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varemærke"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årgang"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["År for aftapning"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indhold"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flaskens nummer"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal producerede flasker"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktionsmetode"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status for destilleriet"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassificering"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen ordre på denne flaske"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker-sammensætning"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg og tilføj en flaske til diagrammet"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, ticker eller produktnavn"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den flaske, du leder efter, er ikke blevet fundet"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salg"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilder"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intet billede tilgængeligt for denne reference"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fadnummer"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se historiske priser"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direkte ordrer"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal flasker"])},
    "orders": {
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret købsordre"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en salgsordre"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en Swap Taker-ordre"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en Swap Giver-ordre"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en blokhandel"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vægtenhed"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhedsstørrelse"])},
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køb"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salg"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en ordre"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuel"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blanding"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen ordrer i øjeblikket."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Købsordre"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Købspris"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mængde"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valgmuligheder"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planlæg en slutdato og et sluttidspunkt?"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en købsordre"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sælg ordre"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mængde"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vægt"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Længde"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bredde"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Højde"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj billeder"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valgmuligheder"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planlæg en slutdato og et sluttidspunkt?"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en salgsordre"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre oprettet med succes"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din anmodning er blevet behandlet. Du vil snart blive kontaktet."])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regler, man skal overholde som"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undgå at oversvømme ordrebogen unødigt"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begræns dine ordrer til reelle transaktioner."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undgå gentagne afslag"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentagne afvisninger af et match kan opfattes som ondsindet eller uansvarligt. Sørg for kun at indgå transaktioner, hvis du har tænkt dig at gennemføre dem."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respektér gendannelsestider"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentagen manglende afhentning af pakker til tiden kan føre til besvær for sælgeren og ekstra omkostninger."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respekter ordrebogens integritet"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At manipulere ordrebogen, at deaktivere en interesse, efter at et match er blevet afvist, osv. betragtes som vildledende praksis. Sørg for, at dine handlinger er gennemsigtige."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vær forberedt på de tilknyttede omkostninger"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokale import- og toldafgifter kan forekomme. Sørg for at være forberedt på at betale dem, når du afslutter en transaktion."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv nøjagtige oplysninger"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for, at beskrivelsen af flasken er nøjagtig og sandfærdig. Det hjælper køberne med at træffe en informeret beslutning. Giv et fuldt billede af for- og bagside."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respekter detaljerne i tilbuddet"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du forpligter dig til at sælge en flaske, skal du overholde denne forpligtelse. Ethvert tilbud, der ikke overholdes, vil få konsekvenser for sælgeren."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garanti for kvaliteten af emballagen og dens indhold"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentagne leveringer af beskadigede pakker (forringelse, brud, lækage) og forkert indhold har indflydelse på dig. Sørg for at pakke dine flasker omhyggeligt."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respekter leveringstider"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis du gentagne gange ikke sender til tiden, kan det påvirke din adgang til transaktionsområdet. Planlæg i overensstemmelse hermed for at undgå forsinkelser."])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakkens reference:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan beskrive dit problem her for at gøre det lettere at løse problemet"])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft modtagelse af pakke"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ved at bekræfte modtagelsen af pakken bekræfter du, at produktet lever op til dine forventninger."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiver"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slettet"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venter"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelse"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send til"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I transit"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveret"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retssager"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afsluttet"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaljer om levering"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporingsnummer"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalt"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spor min pakke"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se detaljer om levering"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ved levering"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter eller afvis venligst dine forslag, før du opretter en ny ordre."])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link til Blocktrade"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikt link til at dele"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikker transaktion med vores betalingsudbyder Mangopay"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletning"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download følgesedlen"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ved verifikation"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonym transaktion (3 EUR inkl. moms)"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal uploade billeder af flasken"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan ikke indsætte det samme billede mere end én gang"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun billeder på mindre end 7 MB og med filtypen .JPG, .JPEG og .png accepteres."])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din ordre kan ikke være gyldig i mindre end en time"])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen købsordrer i øjeblikket."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen salgsordrer i øjeblikket."])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køberen kender ikke din identitet"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hjem-samling"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din pakke afhentes hjemme hos dig af UPS"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for indsamling"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første afhentningstidspunkt"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste afhentningstidspunkt"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne ordre matcher ikke dine eller modpartens landepræferencer."])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj billeder"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eller træk og slip"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun .JPG, .PNG og .JPEG (maks. 7 MB pr. foto)"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilgængelig i relæpunkter"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal bekræfte din identitet for at få adgang til transaktionssektionen."])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjek min identitet"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret advarsel"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du modtager en e-mail, når der oprettes en salgsordre."])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du modtager en e-mail, når der oprettes en købsordre."])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du modtager en e-mail, når der oprettes en ordre."])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For at oprette en alarm skal du gå til den produktside, du vælger."])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priser (valgfrit)"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din advarsel er blevet oprettet"])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl ved oprettelse af din advarsel"])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din pris skal være mellem 50 og 30000 EUR eller 0 EUR."])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sæt denne notifikation på pause"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genaktiver denne advarsel"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiveret"])},
      "blocktrade_link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopier linket for at dele din Blocktrade-ordre"])},
      "blocktrade_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din blocktrade er tilgængelig i højst 2 timer. Hvis du vil dele denne private salgsordre, skal du kopiere linket ved at klikke på knappen ovenfor."])},
      "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade-linket er blevet kopieret korrekt"])},
      "wrong_value_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjek venligst dit input"])},
      "bottle_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pris pr. enhed (50-30000)"])},
      "package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakkens dimensioner"])},
      "add_bottle_basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en flaske"])},
      "choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udløbsdato"])},
      "duplicate_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan ikke tilføje den samme reference to gange"])},
      "maximum_sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nået den maksimale mængde, der er tilladt for et enkelt salg"])},
      "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en ekstra pakke"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prisliste"])},
      "min_pictures_required_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal tilføje mindst"])},
      "min_pictures_required_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Billeder"])},
      "storage_subscription_information_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebyrerne beregnes pro rata frem til udgangen af måneden og omfatter også den følgende måned. Du vil derefter blive debiteret hver den 1. i måneden for"])},
      "storage_enter_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebyr for adgang til lageret"])},
      "storage_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisk lager"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiske priser"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en flaske"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graferne er kun til information. Vi påtager os intet ansvar for eventuelle fejl."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vores historiske data er samlet"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salg"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salg"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fra"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forskellige kilder."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kilde."])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebyrer"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnit"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Til toppen"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linjediagram"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lysdiagram"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muligheder for diagrammer"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ydeevne"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fra"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priserne vises med en forsinkelse på"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["måned"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen historiske prisdata tilgængelige. Hvis vi endnu ikke har inkluderet dem, bedes du kontakte support for at få dem tilføjet."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende visning"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dagligt"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månedligt"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årligt"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugentlig"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sammenlign med"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen flasker valgt"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne flaske er allerede på kortet"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nået grænsen på 6 flasker for diagrammet"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se komposit"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporter en fejl"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type af fejl"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemærkninger"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valgfrit"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskriv problemet mere præcist her..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skærmbilleder / Billeder"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. 2 billeder."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
    },
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaljer om levering"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din anmodning er blevet taget i betragtning"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udgivelsesdato"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit-priser"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tændstikker"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match køber"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match sælger"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køb"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter og sælg"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se mine ", _interpolate(_named("num_transac")), " aktuelle transaktioner"])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis billeder"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min købsordre"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sælg ordre"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køber"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I gang"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Købsordre"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpart"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min salgsordre"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen matches at vise."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontooprettelse og funktioner tilgængelige for alle"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktioner"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urevideret konto"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræftet konto"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyhedsfeed"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis og ubegrænset fuld adgang"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiske priser"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanceret visualisering"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 måneders udsættelse"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 måneders udskydelse"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portefølje"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 måneders udskudt værdiansættelse"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 måneders udskudt værdiansættelse"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 unikke tickere"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 unikke tickere"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 tickere om dagen"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handel"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se aktive ordrer"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen transaktionsadgang"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID-verifikation er gratis for privatpersoner og koster penge for professionelle. KYC udføres af vores betalingstjenesteudbyder Mangopay."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubegrænset fuld adgang"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID kopieret"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fil"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer en CSV- eller Excel-fil"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flaske"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg alle"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fravælg alle"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter en flaske"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portefølje"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For at vejlede dig,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload en fil"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eller træk og slip"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun CSV- og XLSX-filer"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en importmodel"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søjler"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitlig beholdningsperiode"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portefølje"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omdøb"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flyt til en anden mappe"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omdøb mappe"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navnet på filen er påkrævet"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det maksimale antal tegn må ikke overstige 30"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importør"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametre"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistik"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolonner i tabellen"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabel med optioner"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valgmuligheder"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fold alle"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fold alt ud"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis solgte aktier"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flasker"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickers"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mængde"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettopris"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttopris"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommissionen"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebyrer"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opbevaringssted"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holding"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedhæftede filer"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvor kan man købe"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en fil"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en flaske"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn på overordnet mappe"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yderligere kolonner"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolonne-ordre"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine flasker"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for køb"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj prisindstillinger"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salgsdato"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for udveksling"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salg"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køb"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der blev ikke fundet nogen flasker, der matchede din søgning."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg den overordnede mappe"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filens navn"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en konto"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift flaske"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flasker på lager"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flasker i din besiddelse"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikke referencer"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejede flasker ganget med den respektive købspris"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejede flasker ganget med markedsværdi"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus valeur en cours, forskel mellem markedsværdi og købspris ganget med mængde"])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende performance"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende årligt afkast"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitspris for alle flasker"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitlig tid, hvor en købers beholdning forbliver i porteføljen"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine flasker købt indtil videre"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine solgte flasker indtil videre"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlede udgifter til dato"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlet salg til dato"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akkumuleret gevinst fra første dag til dato"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realiseret gevinst i den valgte periode"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afkast beregnet fra første dag (tager kun højde for solgte flasker)"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effektivt årligt udbytte (solgte flasker)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikke referencer"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indledende værdiposition"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende performance"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annualiseret afkast"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historisk årligt afkast"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historisk realiseret afkast"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennemsnitlig pris"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flasker købt"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker unik"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Værdiansættelse"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latent gevinst"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solgte flasker"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlede udgifter"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlet realiseret gevinst"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realiseret gevinst (periode)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historisk realiseret afkast"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlet salg"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for køb/salg"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettopris"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priserne er ekskl. moms."])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priser med gebyrer"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priser med moms"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettopris"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brutto købspris"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brutto salgspris"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opbevaringssted"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mængde"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køb"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salg"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencedetaljer"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil slette denne fil?"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel-fil"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips til din excel-fil"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolonner, der vises"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg alle"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brugt plads"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en konto"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en flaske"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer en fil"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende flaske"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende flasker"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende reference"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende referencer"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelt dossier"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flyt til en mappe"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flasker i mappen"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencer i dossieret"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flyt til en mappe"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebyrer"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beholdninger (dag)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedhæftede filer"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvor kan man købe"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sted for salg"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solgt mængde"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WAB pris"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WASD-priser"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivklasse"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til produktblad"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidste pris"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilitet"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fravælg alle"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["udvalgt(e) flaske(r)"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flyt dig"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sletning"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet flaske"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porteføljen er i øjeblikket kun tilgængelig på pc, men vil snart være tilgængelig på mobil."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porteføljens volatilitet"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilitet for spiritus"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destilleriets volatilitet"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage-volatilitet"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilitetens alder"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porteføljepræmie kommer snart"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se flere detaljer"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til produktsiden"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fil oprettet"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filen er blevet ændret"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filen er blevet slettet"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl ved oprettelse af filen"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flasken er blevet tilføjet"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flasken er blevet modificeret"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flasken er blevet flyttet"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filen blev importeret med succes"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original fil :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du flytter denne mappe og alle flaskerne i den."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din fil er blevet flyttet"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umuligt at slette, da der er flasker i denne mappe"])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send et tilbud"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun .jpg-, .jpeg- og .png-filer på mindre end 3 Mb er tilladt."])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaljer om transaktioner"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt over ordrer"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsendelsesomkostninger"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skat"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køb"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sælg"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvis"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sælger :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebyrer for levering"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsgebyrer"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlet beløb"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inklusive moms på"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS-leveringsomkostninger"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsmetode"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metode til betaling"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS Forsikring"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domicil"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point relais"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilgængelige tilbagetrækningspunkter"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem denne adresse til fremtidige køb"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen mislykkedes. Prøv venligst igen"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen er blevet gennemført med succes"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din betaling er ved at blive verificeret"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vent venligst et par sekunder"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læg i kurv"])},
    "remove_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern fra kurven"])},
    "storage_exit_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebyrer ved udtræden"])},
    "storage_exit_payment_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne funktion kommer snart. Kontakt venligst kundeservice for at frigive dine flasker."])},
    "inssurance_global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsikring"])},
    "inssurance_global_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsikring inkluderet"])},
    "insurrance_option_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forsikr mine flasker i tilfælde af skade"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoindstillinger"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration af abonnementer"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mine abonnementer"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metode til betaling"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturahistorik"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min konto"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køb og salg"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankoplysninger"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikkerhed og 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oplysninger om"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virksomhed"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Høflighed"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalitet"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fødselsdato"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postnummer"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byen"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en anden leveringsadresse"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsadresse"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet leveringsadresse"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktureringsadresse"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendt til"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen information"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontohaver"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sendt på"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift din RIB"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobbelt autentificering"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtag en kode, hver gang du logger ind, og hver gang der foretages en ændring på din konto."])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobbelt autentificering via e-mail"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobbelt autentificering via SMS"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste forbindelser"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se historikken for de sidste 10 forbindelser til din konto på alle enheder."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtag nyhedsbrevet"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debetkort nr."])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføjet den"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udløber den"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skift mit bankkort"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har endnu ikke registreret et betalingskort."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indstil denne adresse som standard"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foretrukken leveringsadresse"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændring af e-mailadresse"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende e-mailadresse"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny e-mailadresse"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændring af telefonnummer"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuværende telefonnummer"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyt telefonnummer"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj en leveringsadresse"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilmeld dig"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forkert e-mail"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forkert telefonnummer"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har allerede denne e-mailadresse"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har allerede dette telefonnummer"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den aktuelle måneds udgifter"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnent siden"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er ikke abonnent"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årlige abonnementer"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Næste indsamlingsdato"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonneret indtil"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmeld dit abonnement"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udforsk tilgængelige abonnementer på historiske priser"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmeld dit abonnement"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit abonnement udløber den"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berørte abonnementer"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft annullering"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genoptag abonnement"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsæt med et abonnement"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den næste betaling vil blive foretaget den"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berørte abonnementer"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling vil blive foretaget hver"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["til en pris af"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræftelse af opsvinget"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forpligtet indtil"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["måned"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historiske priser på premium"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturahistorik"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download alt"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priserne"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download faktura"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalt"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ikke en faktura"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaljer om levering"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spor pakken"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anslået levering"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr. transaktion"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast venligst den kode, du har modtaget via e-mail"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast venligst den kode, du har modtaget via telefon"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny e-mail"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ny telefon"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast koden, som du har modtaget via e-mail"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indtast den kode, du har modtaget via telefon"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktura og leveringsadresse"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine præferencer er blevet registreret"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fødeby"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fødselsland"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifikationer"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelse af en købsordre"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelse af en salgsordre"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelse af en swap-ordre"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelse af en blocktrade-ordre"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En køber afviste matchet"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salgsordre afventer validering"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkend modtagelse af SMS (kontovalidering og/eller 2FA)"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Præferencer for kommunikation"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS-relæpunkt"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muligheder for køb"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salgsmuligheder"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlighed ved køb"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synlighed i salget"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lande, der skal udelukkes"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lande, der skal inkluderes"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopier mine købspræferencer til salg"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er taget højde for dine ændringer"])}
    },
    "update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændre kontooplysninger"])},
    "wrong_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjek venligst dit brugernavn"])},
    "catch_update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ikke muligt at opdatere dine oplysninger. Kontakt venligst support, hvis problemet fortsætter."])},
    "pseudo_already_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette brugernavn er ikke længere tilgængeligt, vælg venligst et andet"])},
    "update_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændre mine oplysninger"])}
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociale netværk"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understøttelse"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridisk"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hjælpecenter"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OFTE STILLEDE SPØRGSMÅL"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekruttering"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporter en fejl"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status for tjenester"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juridiske oplysninger"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VILKÅR OG BETINGELSER"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration af cookies"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virksomhed registreret i Frankrig"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebyrer for levering"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatter"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Køb"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afvis"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oversigt over ordrer"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solgt del :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebyrer for levering"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salgsafgifter"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlet beløb"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igangværende retssager"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeld en tvist"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der udføres ingen transaktioner."])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil filtre"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udgivet den :"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændret den :"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer artikler"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen varer fundet."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilgængeligt beløb"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I venteposition"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlet beløb"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbagetrækning"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevægelser"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling foretaget"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen transaktioner at vise."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overførsel"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indgående overførsel"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udgående overførsel"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling til sælger"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbagetrækning"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udbetaling til min bankkonto"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hævning af tilgængelige midler"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekræft udbetaling"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overførslen til din bankkonto er gennemført med succes"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overførslen til din konto mislykkedes. Hvis problemet fortsætter, bedes du kontakte kundeservice."])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min tegnebog (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resterende beløb efter transaktionen :"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modtaget betaling"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Få adgang til min handelshistorik"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overfør penge direkte til din wallet for at undgå betalingsgebyrer"])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejer :"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN :"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC :"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dine midler vil være tilgængelige inden for 2 til 3 arbejdsdage."])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element kopieret med succes"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopi"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilvejebringelse af tegnebog"])},
    "instant_payout_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling sker som standard ved øjeblikkelig overførsel"])},
    "available_amount_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beløb til rådighed for udbetaling :"])},
    "banking_account_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkonto, der slutter med :"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerede filtre"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 filtre er nødvendige for søgninger uden nøgleord"])}
  },
  "ratings": {
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderinger"])},
    "ratings_phone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedømmelser :"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemærk"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse"])},
    "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelsen kan indeholde op til 500 tegn"])},
    "create_error_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjek venligst, at din vurdering er mellem 1 og 100, og at din beskrivelse er mindre end 500 tegn lang."])},
    "create_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en evaluering"])},
    "create_btn_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret en evaluering"])},
    "see_or_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se eller ændr rating"])},
    "update_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændre værdiansættelse"])},
    "delete_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet vurdering"])},
    "rating_explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For at oprette en evaluering skal du gå til en produktside"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategori, navn, score..."])},
    "description_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelsen er privat. Den vil ikke være offentligt tilgængelig."])},
    "not_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valgfri"])},
    "rating_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum antal værdiansættelser ikke nået for at lave et gennemsnit."])},
    "pseudo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fra 5 til 50 tegn (bogstaver og tal accepteres)"])},
    "empty_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er endnu ingen vurdering af denne flaske."])},
    "ranking_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udgangspunktet for alle brugere."])},
    "ranking_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anerkender regelmæssige bidragydere."])},
    "ranking_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indikerer avanceret engagement og ekspertise."])},
    "ranking_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et ægte engagement."])},
    "ranking_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anerkender en ledende rolle i samfundet."])},
    "ranking_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kun for meget aktive brugere."])},
    "ranking_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den ultimative rang for de mest dedikerede bidragydere."])},
    "rewards_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belønningerne for hvert niveau vil blive annonceret i januar!"])},
    "pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kælenavn"])},
    "private_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gør min kommentar privat"])},
    "profil_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "rating_unitary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Værdiansættelse"])},
    "rating_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vurderinger"])}
  },
  "storage": {
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opbevaring"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel, reference..."])},
    "invoice_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download købsfaktura"])},
    "check_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se eller ændr flasken"])},
    "store_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for opbevaring"])},
    "exit_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag mine flasker ud"])},
    "supplier_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lager"])},
    "reference_u": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Henvisning"])},
    "reference_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencer"])}
  },
  "order_renew": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din ordre er blevet fornyet med succes"])}
  }
}